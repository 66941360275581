import React, { useState, useEffect, useContext } from 'react';
import { CircularProgress, Grid, Button, Skeleton } from '@material-ui/core';
import { Backdrop } from '@mui/material';
import { Add } from '@mui/icons-material';

import { appContext } from '../../context'
import { PopUpBank } from '../components/PopUpBank';
import QuestionsBankCard from './QuestionsBankCard';
import sortQuestionsBanks from './utils/sortQuestionsBanks';

export default function QuestionsBankList({ courseId, loading, setLoading, getBanks }) {

  const { banks, setBanks, sortMethod } = useContext(appContext);

  const [showModalCreateBank, setShowModalCreateBank] = useState(false);

  const openModalCreateBank = () => {
    setShowModalCreateBank(prev => !prev);
  }

  useEffect(() => {
    const sorted = sortQuestionsBanks(banks?.[courseId], sortMethod);
    setBanks(prev => { return { ...prev, [courseId]: sorted } });
  }, [sortMethod]);

  return (
    <>
      <PopUpBank showModal={showModalCreateBank} getBanks={getBanks} setShowModal={setShowModalCreateBank} courseId={courseId} />
      <Button sx={{ mt: 2, mb: 2 }} variant="text" startIcon={<Add />} onClick={openModalCreateBank}>Criar novo banco</Button>
      <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 4 }}>
        {
          banks?.[courseId]
            ? banks[courseId].map(bank => (
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <QuestionsBankCard getBanks={getBanks} bank={bank} courseId={courseId} />
              </Grid>
            ))
            : <>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Skeleton variant="rect" height={100} />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Skeleton variant="rect" height={100} />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Skeleton variant="rect" height={100} />
              </Grid>
            </>
        }
      </Grid>
    </>
  );
}
