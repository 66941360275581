import React, { useRef, useEffect, useCallback, useState, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import ReactTooltip from 'react-tooltip';

import TextEditor from '../TextEditor'

import { FaTrashAlt, FaQuestion } from 'react-icons/fa';

import backendAPI from '../../../services/backendAPI';

import { appContext } from '../../../context';

import styled from 'styled-components';

import { MdClose } from 'react-icons/md';

import { Typography } from '@material-ui/core';
import { Box } from '@mui/system';

const ModalWrapper = styled.div`
    position: absolute;
    top: 11vh;
    left: 0;
    width: 100%;
    height: fit-content;
    background: #fff;
    color: #000;
    z-index: 10;
    display: flex;
    justify-content: center;
`;

const ModalContent = styled.div`
    * {
        font-size: 1rem;
    }
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    line-height: 1.8rem;
    color: #141414;
    padding: 50px;
    h1 {
        display: flex;
        text-align: top;
        position: relative;
        font-size: 1.8rem;
        margin: 15px 0;
    }
    h2 {
        padding: 20px 0;
        font-size: 1.5rem;
    }
    h3 {
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        margin: 25px;
    }
    h5 {
        text-align: center;
        align-items: center;
        font-size: 0.5rem;
        margin: 5px;
    }
    button {
        width: 90%;
        height: 30px;
        cursor: pointer;
    }
    * input[type=checkbox], * input[type=radio] {
        cursor: pointer;
    }
    select {
        font-size: 1rem;
        padding: 10px;
        cursor: pointer;
    }
    .trash {
        cursor: pointer;
    }
    .tip-icon {
        display: inline;
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
    .quill {
        margin-bottom: 20px;
    }
`;

const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;

const Button = styled.div`
  display: grid;
  place-items: center;
  cursor: pointer;
  background: green;
  width: 100px;
  height: 50px;
  border: none;
  border-radius: 5px;
  margin: 20px 0;
  color: #fff;
  font-size: 11pt;
`;


const AddOption = styled(Button)`
    width: 100%;
    align-self: center;
    background: #3e96e4;
    font-size: 1.3rem;
    margin-top: 20px;
`;

const InputBlock = styled.div`
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    font-size: 1rem;

    input {
        padding: 10px;
    }

    .optionBox {

        display: flex;
        flex-direction: column;
        width: 100%;

        .settings {
            display: flex;
            align-items: center;
            border: 1px solid #ccc;
            border-bottom: 0;
            width: fit-content;
            padding: 15px 20px;

            * {
                padding: 0;
                margin: 0;
            }

            div {
                display: flex;
                align-items: center;

                input {
                    width: 14pt;
                    height: 14pt;
                    margin-right: 10px;
                    cursor: pointer;
                }

                label {
                    font-size: 11pt;
                    font-weight: bold;
                    cursor: pointer;
                }

                svg {
                    margin-right: 10px;
                }

            }

            div:first-child {
                border-right: 1px solid #ccc;
                padding-right: 20px;
                margin-right: 20px;
            }
        }

        .quill {
            flex: 1;

            .ql-container {
                height: unset !important;
            }

        }

    }
`;


export const PopUpQuestions = ({ visible, closeModal, bank, questionData, getQuestions, newQuestion = true }) => {
  const [statement, setStatement] = useState(questionData?.statement || '');
  const [tag, setTag] = useState(questionData?.tag || '');
  const [type, setType] = useState(questionData?.type || 'singleChoice');
  const [options, setOptions] = useState(questionData?.options || [
    { text: '', image: '', isCorrect: false }
  ]);
  const [feedback, setFeedback] = useState(questionData?.feedback || '');
  const [images, setImages] = useState(questionData?.images || []);

  useEffect(() => {
    setStatement(questionData?.statement || '');
    setOptions(questionData?.options || [
      { text: '', image: '', isCorrect: false }
    ]);
    setTag(questionData?.tag || '');
    setFeedback(questionData?.feedback || '');
    setType(questionData?.type || 'singleChoice');
    setImages(questionData?.images || []);
  }, [questionData]);

  const { googleCredentials } = useContext(appContext);

  const modalRef = useRef();

  const handleSubmit = async () => {

    try {

      if (newQuestion) {
        const response = await backendAPI(googleCredentials).post(`/questao`, {
          statement,
          options,
          tag: tag ? tag : undefined,
          bank: bank._id,
          type,
          feedback: feedback ? feedback : undefined,
        });
        getQuestions();
        closeModal();

        // Reseta o estado
        setStatement('');
        setTag('');
        setOptions([
          { text: '', image: '', isCorrect: false },
        ]);
        setType('singleChoice');
        setFeedback('');
        setImages([]);
      } else {
        await backendAPI(googleCredentials).put(`/questao?id=${questionData._id}`, {
          statement,
          options,
          tag: tag ? tag : undefined,
          bank: bank._id,
          type,
          feedback: feedback ? feedback : undefined,
        })
        closeModal();
        getQuestions();

      }

    } catch (err) {
      console.log(err)
      if (err.response.status === 413) {
        alert('Erro! Questão muito grande! Tente remover imagens muito pesadas e texto excessivamente grandes');
      }
    }


  }

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: visible ? 1 : 0,
    transform: visible ? 'translateY(0%)' : 'translateY(-100%)'
  });

  const keyPress = useCallback(e => {
    if (e.key === 'Escape' && visible) {
      closeModal();
    }
  }, [closeModal, visible]);

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
  }, [keyPress]);

  useEffect(() => {
    let values = [...options];
    let correctCounter = 0;
    if (type === 'singleChoice') {
      values = values.map(option => {
        if (correctCounter > 0 || option.isCorrect === false)
          return { ...option, isCorrect: false }

        correctCounter += 1;
        return { ...option, isCorrect: true }
      });
    }

    setOptions(values);
  }, [type]);

  const handleFeedbackChange = event => {
    let validText = ''

    for (let i in event.target.value) {
      if (i < 4000) validText += event.target.value[i];
    }

    setFeedback(validText);
  }

  const handleOptionsInputChange = (value, index) => {
    let values = [...options]

    values[index].text = value;
    setOptions(values);
  }

  const handleRemoveOption = index => {
    let values = [...options];
    values.splice(index, 1);
    setOptions(values);
  }

  const handleCorrects = (event, index) => {
    let values = [...options];
    console.log(type)
    if (type === 'singleChoice')
      values = values.map(option => { return { ...option, isCorrect: false } });

    values[index].isCorrect = !values[index].isCorrect;
    setOptions(values);
  }

  return (
    <>
      {visible ? (
        <ModalWrapper showModalQuestions={visible}>
          <ModalContent>

            <InputBlock>
              <Typography variant="h2">Enunciado:</Typography>
              <Box>
                <TextEditor value={statement} setValue={setStatement} />
              </Box>
            </InputBlock>

            <InputBlock>
              <Typography variant="h2">Categoria:</Typography>
              <input placeholder="Sem categoria" value={tag} onChange={event => setTag(event.target.value)} type="text" />
            </InputBlock>

            <InputBlock>
              <Typography variant="h2">Tipo</Typography>
              <select value={type} onChange={event => setType(event.target.value)}>
                <option value="singleChoice">Múltipla escolha (Única resposta correta)</option>
                <option value="multipleChoice">Caixa de seleção (Duas ou mais respostas corretas)</option>
                <option value="open">Questão aberta (Resposta dissertativa)</option>
              </select>
            </InputBlock>
            {
              type != 'open' && <InputBlock>
                <Typography variant="h2">Alternativas:</Typography>

                {
                  options.map((option, index) => (
                    <div className="optionBox" key={index}>
                      <div className="settings">
                        <div>
                          <input
                            type={type === "singleChoice" ? "radio" : "checkbox"}
                            name='option' defaultChecked={options[index].isCorrect}
                            onChange={(event) => handleCorrects(event, index)}
                            id={index}
                          />

                          <label for={index}>Correta</label>
                        </div>

                        <div>
                          <FaTrashAlt size={18} color="red" className="trash" onClick={() => handleRemoveOption(index)} />
                          <label onClick={() => handleRemoveOption(index)}>Excluir alternativa</label>
                        </div>
                      </div>

                      <TextEditor className="answerQuestion" value={option.text} setValue={value => handleOptionsInputChange(value, index)} />
                    </div>
                  ))
                }

                <AddOption onClick={() => setOptions([...options, { text: '', image: '', isCorrect: false }])}>+ Adicionar opção</AddOption>
              </InputBlock>
            }


            {/* <InputBlock>
                                    <div>
                                        <h2>Feedback (opcional):</h2>
                                        <span data-tip data-for="feedback-tooltip" className="tip-icon"><FaQuestion data-tip data-for="save-tooltip" /></span>
                                    </div>
                                    <textarea onChange={handleFeedbackChange} value={feedback}></textarea>
                                    <span>{feedback.length}/4000</span>
                                </InputBlock> */}

            <Button onClick={handleSubmit}>{newQuestion ? 'Criar' : 'Salvar'}</Button>
          </ModalContent>
          <CloseModalButton aria-label='Close modal' onClick={() => closeModal()} />
          <ReactTooltip className="tip" id="feedback-tooltip" effect="solid" type="dark" place="right">
            <p>Uma mensagem, ou comentário da questão, que será exibida para os alunos assim que a nota for lançada.</p>
          </ReactTooltip>
        </ModalWrapper>
      ) : null}
    </>
  );
}
