import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 5% 0 5%;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: rgb(25, 156, 25);

    .mountains {
        position: absolute;
        bottom: 0;
        left: 5%;
        max-height: 70vh;
        width: 40vw;
        z-index: 0;
    }

    .titles {
        z-index: 10;
    }

    .titles-centered {
        display: none;
    }

    .left-side {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .right-side {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .right-side h2, .right-side span {
      padding: 1%;
      font-size: 18pt;
      font-family: 'Montserrat', sans-serif;
      color: #FFF;
    }

    .login {
        position: absolute;
        bottom: 18%;
        right: 17%;
        text-align: center;
        width: 90px;
        height: 80px;
        span {
            display: none;
        }
    }

    .right-side .login:hover {
      cursor: pointer;
    }

    @media (max-width: 1024px) {
        .mountains {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                width: 100vw;
                max-height: 50vh;
        }
        .titles {
                display: none;
        }
        .titles-centered {
                display: initial;
                position: absolute;
                top: 10%;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
        }
        .login {
                position: absolute;
                border-radius: 30px;
                width: 70%;
                height: 7%;
                padding: 10px;
                top: 35%;
                left: 50%;
                background: #FFF;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                transform: translateX(-50%);
                gap: 10%;
                div {
                    padding: 0 !important;
                    width: 10% !important;
                    display: flex;
                    align-items: center;
                }
                span {
                    color: #333;
                    display: inline;
                    font-size: 3vh;
                    margin-left: 60%;
                }
                h2 {
                    display: none;
                }
        }
    }

    @media (max-height: 500px) {
        .mountains {
            display: none;
        }
        .login {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);
            height: fit-content;
            span {
                font-size: 6vh;
            }
        }
    }
`;
