import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

import { Menubar } from './styles';

import { useHistory } from 'react-router-dom';
import { appContext } from '../../../context';
import { fetchCourses } from '../../../utils/fetchCourses';

import Loading from '../../Loading';

function MenuBar({ sidebar, setSidebar }) {
    const history = useHistory();

    const { googleCredentials, courses, setCourses, signOut, isTestInterface } = useContext(appContext);


    useEffect(() => {

        if (courses[0] === 'searching') {
            fetchCourses(googleCredentials)
                .then((data) => setCourses(data));
        }

    });

    if (courses[0] === 'searching') return <></>
    if (!courses) return <></>

    return (
        <>
            <Menubar active={sidebar}>
                <header>
                    <h1>Turmas</h1>
                    <span onClick={() => setSidebar(false)}>ⓧ</span>
                </header>
                <ul>
                    {courses.map((course) => (
                        <li>
                            <a className="navbar-listcourses" href={`${process.env.REACT_APP_DOMAIN}/turma/${course.id}`}>
                                <div className="iconGSA">
                                    <a href={course.alternateLink} target="_blank" rel="noopener">
                                        <img title="Abrir no GSA" src={require('../../../assets/images/google-classroom.svg')} />
                                    </a>
                                </div>
                                <div>
                                    <h3 className='menu-item'>{course.name}</h3>
                                    <h5>{course.section}</h5>
                                </div>
                            </a>
                            <hr></hr>
                        </li>
                    ))}
                </ul>
            </Menubar>
        </>
    )
}

export default MenuBar;
