import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

export const ModalWrapper = styled.div`
    width: fit-content;
    height: 90vh;
    padding: 30px 50px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: start;
    z-index: 10;
    border-radius: 10px;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 20px;
    h2 {
        margin-bottom: 20px;
        font-size: 1.8rem;
    }
`;

export const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;

export const CoursesList = styled.table`
    width: fit-content;
    height: 85%;
    overflow: auto;
    display: block;
`;

export const Course = styled.td`
    font-size: 1.6rem;
    padding: 10px 30px;
    cursor: pointer;
    background: ${props => (props.index) % 2 !== 0 ? '#ff' : '#c1c1c1'};
    color: ${props => props.original ? '#888' : '#000'};
    :hover {

    }
    label {
        padding: 0 0 0 10px;
        cursor: ${props => props.active ? 'pointer' : ''};
        font-size: 1.2rem;
    }
    input {
        cursor: pointer;
    }
`;

export const ShareButton = styled.button`
    margin-top: 20px;
    width: fit-content;
    background: ${props => props.active ? '#199c19' : '#199c19a9'};
    color: #fff;
    border: 0;
    padding: 10px 30px;
    border-radius: 10px;
    cursor: ${props => props.active ? 'pointer' : ''};
    :hover {
        box-shadow: ${props => props.active ? '1px 1px 5px #888' : ''};
    }
`;
