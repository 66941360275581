import React, { useState, useContext, useEffect } from 'react';
import { Skeleton, Tab, Tabs, Typography } from '@material-ui/core'
import Header from '../components/Header';
import { appContext } from '../../context'
import {
  GlobalStyles,
  Container,
  ListContainer,
  ListHeader,
} from './styles';
import QuestionsBankList from './QuestionsBankList';
import { Box } from '@material-ui/system';
import TestList from './TestList';
import backendAPI from '../../services/backendAPI';
import sortTests from './utils/sortTests';
import sortQuestionsBanks from './utils/sortQuestionsBanks';

export default function ListCourseBanks({ location }) {

  const [tab, setTab] = useState(0);
  const [loadingTests, setLoadingTests] = useState(false);
  const [loadingBanks, setLoadingBanks] = useState(false);

  const { googleCredentials, courses, sortMethod, setSortMethod, setTests, tests, banks, setBanks } = useContext(appContext);

  const courseId = location.pathname.split('/')[2];
  const course = courses.find(course => course.id === courseId);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  }

  const getTests = async () => {
    setLoadingTests(true);
    try {
      const response = await backendAPI(googleCredentials).get(`/provas?course=${courseId}`);
      response.data.forEach((test, index) => test.originalIndex = index);
      const sorted = sortTests(response.data, sortMethod);
      setTests(prev => { return { ...prev, [courseId]: sorted } });
    }
    catch (err) { }
    setLoadingTests(false);
  }

  const getBanks = async () => {
    setLoadingBanks(true);
    try {
      const response = await backendAPI(googleCredentials).get(`/banco?course=${courseId}`);
      response.data.forEach((bank, index) => bank.originalIndex = index);
      const sorted = sortQuestionsBanks(response.data, sortMethod);
      setBanks(prev => { return { ...prev, [courseId]: sorted } });
    }
    catch (err) { }
    setLoadingBanks(false);
  }

  useEffect(() => {
    getTests();
    getBanks();
  }, []);

  return (
    <>
      <GlobalStyles />
      <Header />
      <Container>
        <Box sx={{ mb: 4, mt: 4 }}>
          <Typography variant="h5">
            {
              course
              ? `${course?.name?.toUpperCase()} ${course?.section ? `- ${course?.section}` : ''}`
              : <Skeleton width={200} />
            }

          </Typography>
        </Box>
        <hr />
        <ListHeader>
          <Tabs
            sx={{ mt: 2 }}
            value={tab}
            onChange={handleChangeTab}
          >
            <Tab label="Provas" />
            <Tab label="Bancos de Questões" />
          </Tabs>
          <div className="sort-method" onChange={(event) => setSortMethod(event.target.id)}>
            <strong>Organizar por</strong>
            <div className="input-block">
              <input type="radio" id="alphabetic" name="sort-method" checked={sortMethod === 'alphabetic'}></input>
              <label for="alphabetic">Ordem Alfabética</label>
            </div>
            <div className="input-block">
              <input type="radio" id="creationDate" name="sort-method" checked={sortMethod === 'creationDate'}></input>
              <label for="creationDate">Data de criação</label>
            </div>
          </div>
        </ListHeader>
        <ListContainer>
          {
          tab === 0
            ? <TestList courseId={courseId} loading={loadingTests} setLoading={setLoadingTests} />
            : <QuestionsBankList getBanks={getBanks} courseId={courseId} loading={loadingBanks} setLoading={setLoadingBanks} />
          }
        </ListContainer>

      </Container>
    </>
  )
}
