import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import secondsToTime from '../../utils/secondsToTime'

export const DueTime = styled.div`
    font-weight: bold;
    width: 100%;
    margin-bottom: 30px;
    p {
        color: rgb(25, 156, 25);
    }
`;
export default function ({ test }) {
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [timerInterval, setTimeInterval] = useState();

    useEffect(() => startCountdown(), []);

    const startCountdown = () => {
        if (!test.test.timeToDo) return

        clearInterval(timerInterval);
        const startTime_Timestamp = new Date(test.startTime).getTime();
        const now_Timestamp = new Date().getTime();
        const elapsedMilliseconds = now_Timestamp - startTime_Timestamp;

        const remainingMilliseconds = (test.test.timeToDo * 60 * 1000 + startTime_Timestamp) - elapsedMilliseconds;
        const remainingSeconds = (remainingMilliseconds - startTime_Timestamp) / 1000;

        setTimeRemaining(remainingSeconds);

        setTimeInterval(setInterval(() => {
            setTimeRemaining(prev => prev - 1);
        }, 1000));

    }

    return (
        <DueTime>
            <Typography fontWeight='bold' variant="body1" component='h6'>Tempo restante:</Typography>
            <Typography variant="body1">{ test.test.timeToDo ? secondsToTime(timeRemaining, timerInterval).join(':') : 'Sem tempo limite' }</Typography>
        </DueTime>
    )

}
