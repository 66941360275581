import React, { useRef, useEffect, useCallback, useState, useContext } from 'react';
import { useSpring, animated } from 'react-spring';

import backendAPI from '../../../services/backendAPI';

import { appContext } from '../../../context';

import styled from 'styled-components';

import { MdClose } from 'react-icons/md';
import { Alert, Snackbar } from '@material-ui/core';
import GenericSnackbar from '../GenericSnackbar';

import { Typography, Button } from '@material-ui/core';

const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalWrapper = styled.div`
    width: 800px;
    height: 500px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: grid;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 10;
    border-radius: 10px;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    line-height: 18px;
    color: #141414;
    h1 {
        display: flex;
        text-align: top;
        position: relative;
        font-size: 25pt;
    }
    h3 {
        justify-content: center;
        align-items: center;
        font-size: 25pt;
        margin: 20px;
    }
    p {
        text-align: center;
        font-size: 10pt;
        padding: 20px;
        margin-bottom: 10px;
    }
    input {
        height: 40px;
        width: 600px;
        margin-bottom: 40px;
        border-radius: 4px;
        padding: 4px;
        transition: .5s;
        border: 1px solid #777;
        font-size: 12pt;
    }
    input:hover {
        box-shadow: 0 0 5px #ccc;
    }
    button {
        height: 50px;
        width: 280px;
        border-radius: 10px;
        background-color: green;
        color: #fff;
        border: none;
        font-size: 30px;
        box-shadow: 1px 1px 1px solid black;
        cursor:pointer;
        transition: 0.5s;
    }
    button:hover {
        height: 50px;
        width: 280px;
        border-radius: 20px;
        background-color: black;
        color: #fff;
        border: none;
        font-size: 30px;
        box-shadow: 1px 1px 1px solid black;
        cursor:pointer;
        transition: 0.5s;
    }
`;

const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;

export const PopUpBank = ({ showModal, setShowModal, courseId, getBanks }) => {

  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openFailSnackbar, setOpenFailSnackbar] = useState(false);

  const { googleCredentials } = useContext(appContext);

  const modalRef = useRef();
  const nameInputRef = useRef(null);
  const descriptionInputRef = useRef(null);

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccessSnackbar(false);
  };

  const handleCloseFailSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenFailSnackbar(false);
  };

  const handleClick = async () => {

    const name = nameInputRef.current.value;
    const description = descriptionInputRef.current.value;

    try {
      await backendAPI(googleCredentials).post('/banco', {
        name,
        description,
        course: courseId,
      });
      setOpenSuccessSnackbar(true);
      getBanks();
      setShowModal(false);
    } catch (err) {
      setShowModal(false);
      setOpenFailSnackbar(true);
    }
  }

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? 'translateY(0%)' : 'translateY(-100%)'
  });

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false)
    }
  }

  const keyPress = useCallback(e => {
    if (e.key === 'Escape' && showModal) {
      setShowModal(false)
    } else if (e.key === 'Enter' && showModal) {
      handleClick();
    }
  }, [setShowModal, showModal])

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress)
  }, [keyPress])

  return (
    <>
      {showModal ? (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <Typography variant="h3">Crie um banco</Typography>

                <Typography mt={3} variant="h5">Nome do banco</Typography>
                <input autoFocus ref={nameInputRef} type="text" maxLength="50"></input>

                <Typography variant="h5">Descrição</Typography>
                <input type="text" ref={descriptionInputRef} maxLength="80"></input>

                <Button mb={10} variant="h4" onClick={handleClick}>Criar</Button>
              </ModalContent>
              <CloseModalButton aria-label='Close modal' onClick={() => setShowModal(prev => !prev)} />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
      <GenericSnackbar
        text={'Banco criado com sucesso'}
        open={openSuccessSnackbar}
        onClose={handleCloseSuccessSnackbar}
        type='success'
        position={{ vertical: 'top', horizontal: 'center' }}

      />
      <GenericSnackbar
        text={'Erro ao criar o banco'}
        open={openFailSnackbar}
        onClose={handleCloseFailSnackbar}
        type='error'
        autoHideDuration={5000}
        position={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  )
};
