import React from 'react';

import LoginButton from '../components/LoginButton';

import { Container } from './styles';

import Moutains from '../../assets/images/mountains.svg';
import Titles from '../../assets/images/titles.svg';

function Login({ location }) {

    return (
        <Container>
            <div className="left-side">
                <img className="titles" src={Titles} alt="logo" />
                <img className="titles-centered" src={require('../../assets/images/logo-centralizada.png')} alt="logo" />
                <img className="mountains" src={Moutains} alt="montanhas" />
            </div>
            <div className="right-side">
                <div className='login'>
                    <LoginButton className="btnLogin" redirectTo={location?.state?.from?.pathname || '/'} />
                    <h2>Entrar</h2>
                </div>
            </div>
        </Container>
    );

}

export default Login;
