import React from 'react';
import ReactDOM from 'react-dom';
import './global.styles.css';
import App from './App';
import ContextProvider from './context';

//Verifica se a aplicação está em produção
if (process.env.REACT_APP_ENV === 'production')  {
    console.log = function () {};
}

//Arquivo principal da Aplicação, renderiza e executa a função do App
ReactDOM.render(
    <React.StrictMode>
    <ContextProvider>
        <App />
    </ContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
