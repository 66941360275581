import { Backdrop, Button, CircularProgress, Grid, Skeleton } from '@material-ui/core';
import { Add } from '@mui/icons-material';
import React, { useState, useContext, useEffect } from 'react';
import { appContext } from '../../context';
import backendAPI from '../../services/backendAPI';
import TestCard from './TestCard';
import { PopUpCreateTest } from '../components/PopUpCreateTest';
import sortTests from './utils/sortTests';

export default function TestList({ courseId, loading, setLoading }) {

  const { googleCredentials, tests, setTests, sortMethod, banks, setBanks } = useContext(appContext);
  const [showModalCreateTest, setShowModalCreateTest] = useState(false);

  const openModalCreateTest = async () => {
    setShowModalCreateTest(prev => !prev);
  }

  useEffect(() => {
    const sorted = sortTests(tests?.[courseId], sortMethod);
    setTests(prev => { return { ...prev, [courseId]: sorted } });
  }, [sortMethod]);

  return (
    <>
      <Button sx={{ mt: 2, mb: 2 }} variant="text" startIcon={<Add />} onClick={openModalCreateTest}>Criar nova prova</Button>
      <Grid container paddingBottom={2} spacing={2} columns={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 4 }}>
        {
          tests?.[courseId]
            ? tests[courseId].map(test => (
              <TestCard test={test} courseId={courseId} />
            ))
            : <>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Skeleton variant="rect" height={100} />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Skeleton variant="rect" height={100} />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Skeleton variant="rect" height={100} />
              </Grid>
            </>
        }
      </Grid>
      <PopUpCreateTest showModal={showModalCreateTest} setShowModal={setShowModalCreateTest} courseId={courseId} banks={banks?.[courseId] || []} />
    </>
  );

}
