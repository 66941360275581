import React, { createContext, useState, useEffect } from 'react';
import { useGoogleLogout } from 'react-google-login'

export const appContext = createContext();

function ContextProvider({ children }) {

    const { signOut, loaded } = useGoogleLogout({
        clientId: "625991985413-nkuct431idsmte9o9p52bqig4si60bvj.apps.googleusercontent.com",
        redirectUri: `${process.env.REACT_APP_DOMAIN}/login`,
        onLogoutSuccess: () => {
            sessionStorage.clear();
            window.location.href = '/login'
        }
    });

    const [isAuth, setIsAuth] = useState(Boolean(sessionStorage.getItem('oauth2')));
    const [googleCredentials, setGoogleCredentials] = useState(JSON.parse(sessionStorage.getItem('oauth2')));
    const [driveFolder, setDriveFolder] = useState('');
    const [courses, setCourses] = useState(['searching']);
    const [isTestInterface, setIsTestInterface] = useState(false);
    const [banks, setBanks] = useState(null);
    const [tests, setTests] = useState(null);
    const [sortMethod, setSortMethod] = useState(localStorage.getItem('sortMethod'));

    useEffect(() => {
      localStorage.setItem('sortMethod', sortMethod);
    }, [sortMethod]);

    const state = {
        isAuth,
        setIsAuth,

        googleCredentials,
        setGoogleCredentials,

        courses,
        setCourses,

        banks,
        setBanks,

        tests,
        setTests,

        isTestInterface,
        setIsTestInterface,

        driveFolder,
        setDriveFolder,

        sortMethod,
        setSortMethod,

        signOut,
    };

    return <appContext.Provider value={state}>{children}</appContext.Provider>

}

export default ContextProvider;
