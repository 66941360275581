import axios from 'axios';

const googleAPI = (googleCredentials) => {
  const token = googleCredentials.accessToken;
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  });
};

export default googleAPI;
