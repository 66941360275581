import React, { useRef } from 'react';
import styled from 'styled-components';
import { useDetectOutsideClick } from "../../../utils/useDetectOutsideClick";
import { AccountCircle } from '@mui/icons-material';

export default function DropdownMenu({ image, userData, children }) {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  return (
    <Container>
      <div className="menu-container">
        {
          image
            ? <img
              src={image}
              alt='Imagem de Perfil'
              onClick={onClick}
              className="menu-trigger"
              data-tip
              data-for="profile-tip"
            />
            : <AccountCircle
              sx={{ width: '2.5rem', height: '2.5rem', cursor: 'pointer', fill: 'white' }}
              onClick={onClick}
            />
        }
        <nav
          ref={dropdownRef}
          className={`menu ${isActive ? "active" : "inactive"}`}
        >
          <div className="userData">
            <h3>{userData.givenName + ' ' + userData.familyName}</h3>
            <p>{userData.email}</p>
          </div>
          <ul>
            {children}
          </ul>
        </nav>
      </div>
    </Container>
  );
}

export const DropdownItem = styled.li`
    padding: 10px;
    border: 0;
    margin: 0;
    width: 100%;
    height: 50px;
    color: #000;
    display: flex;
    align-items: center;
    font-size: 12pt;
    cursor: pointer;
    border-top: 1px solid #aaa;
    :hover {
        background-color: #f1f1f1;
    }
`;

const Container = styled.div`

    * {
        box-sizing: border-box;
        color: #333;
    }

    nav .triangle {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        float: right;
        transform: translate(-10px, -8px);
    }

    img {
        border: 5px solid #fff;
    }

    nav .userData {

        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 10px;

        h3 {
            font-size: 12pt;
            margin-bottom: 5px;
        }
        p {
            font-size: 10pt;
            color: #777;
        }

    }

    .menu-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .menu-trigger {
        background: #ffffff;
        border-radius: 50%;
        width: 40px;
        cursor: pointer;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        border: none;
        vertical-align: middle;
        transition: box-shadow 0.2s ease;
    }

    .menu-trigger:hover {
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    }

    .menu-trigger span {
        font-weight: 700;
        vertical-align: middle;
        font-size: 14px;
        margin: 0 10px;
    }

    .menu {
        background: #ffffff;
        position: absolute;
        top: 50px;
        right: 0;
        min-width: 250px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-10px);
        transition: 0.2s;
        border-radius: 5px;

        .logout {
            color: #e74040;
        }
    }

    .menu.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
        border: 0;
        width: 100%;
    }

    li {
        border-radius: 0 0 5px 5px;
        color: var(--secondary);
    }

`;
