import React, { useState, useEffect } from 'react';
import { FaClock, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { RiNumbersFill } from 'react-icons/ri';
import styled from 'styled-components';

import secondsToTime from '../../utils/secondsToTime';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12pt;
    margin-bottom: 30px;
    border: 2px solid #333;
    padding: 20px;
    a {
        text-decoration: none;
    }
`;

const Data = styled.a`
    text-align: center;
    color: #000;
    span {
        margin-left: 20px;
        text-transform: uppercase;
    }
`;

export default function Statistics({ submissions }) {

    const [highestGrade, setHighestGrade] = useState(["", 0]);
    const [lowestGrade, setLowestGrade] = useState(["", 0]);

    useEffect(() => {
        setHighestGrade(getHighestGrade());
        setLowestGrade(getLowestGrade());
    }, [submissions])

    const gradeAverage = () => {

        const gradesSum = submissions.reduce((sum, submission) => {
            return sum + submission.grade;
        }, 0);

        const average = gradesSum / submissions.length;

        if (isNaN(average)) return '-';

        return average.toFixed(2);

    }

    const timeAverage = () => {

        const millisecondsSum = submissions.reduce((sum, submission) => {

            const startTimestamp = new Date(submission.startTime).getTime();
            const endTimestamp = new Date(submission.endTime).getTime();

            const difference = endTimestamp - startTimestamp;

            return sum += difference;

        }, 0);

        const averageSeconds = (millisecondsSum / 1000) / submissions.length;

        if (isNaN(averageSeconds)) return '-';

        return secondsToTime(averageSeconds).join(':');

    }

    const getHighestGrade = () => {

        const data = submissions.reduce((data, submission) => {
            if (submission.grade > data[1]) {
                data[0] = submission.submissionId;
                data[1] = submission.grade;
            }
            return data;
        }, ["", 0]);

        return data;

    }

    const getLowestGrade = () => {

        const highest = getHighestGrade()[1];

        let lowest = highest;
        let id = "";

        submissions.forEach(submission => {
            if (submission.grade < lowest) {
                lowest = submission.grade;
                id = submission.submissionId;
            };
        });

        return [id, lowest];
    }

    return (
        <Container>
            <Data>
                <RiNumbersFill />
                <span>Nota média</span>
                <h3>{gradeAverage()}</h3>
            </Data>
            <Data>
                <FaClock />
                <span>Tempo médio de prova</span>
                <h3>{timeAverage()}</h3>
            </Data>
            <Data href={`#${highestGrade[0]}`}>
                <FaArrowUp />
                <span>Maior nota</span>
                <h3>{highestGrade[1].toFixed(2)}</h3>
            </Data>
            <Data href={`#${lowestGrade[0]}`}>
                <FaArrowDown />
                <span>Menor nota</span>
                <h3>{lowestGrade[1].toFixed(2)}</h3>
            </Data>
        </Container>
    )

}
