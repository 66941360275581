import React from 'react';

import Routes from './Routes';
import LoginButton from './pages/components/LoginButton';

function App() {
    return (
        <>
            <div className='display-none'>
                <LoginButton />
            </div>
            <Routes />
        </>
    );
}

export default App;
