import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Login from './pages/Login';
import Courses from './pages/Courses';
// import ListAllBanks from './pages/ListAllBanks';
import ListCourseBanks from './pages/ListCourseBanks';
import PrivateRoute from './PrivateRoutes';
import ListBankQuestions from './pages/ListBankQuestions';
import TestInterfaceForStudents from './pages/TestInterfaceForStudents';
import ListStudentSubmissions from './pages/ListStudentSubmissions';

//Função das rotas,
//Todas as rotas da aplicação se encontram aqui (EXCETO AS PRIVADAS, estas estão em PrivateRoutes.js)
function Routes() {

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/login' component={Login} />
        <PrivateRoute exact path='/' component={Courses} />
        <PrivateRoute exact path='/turma/:course' component={ListCourseBanks} />
        {/* <PrivateRoute exact path='/bancos' component={ListAllBanks} /> */}
        <PrivateRoute exact path='/bancos/:bank' component={ListBankQuestions} />
        <PrivateRoute exact path='/prova/:testId' component={TestInterfaceForStudents} />
        <PrivateRoute exact path='/provas/:testId/respostas' component={ListStudentSubmissions} />
        <Route path='/' component={() => <h2>404 - Not Found</h2>} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
