import React from 'react';
import { SyncLoader } from 'react-spinners';

import './styles.css';

function Loading({ loadingTest }) {
    return (
        <div className='loading-container'>
            <div className='loading'>
                {
                    loadingTest && <p>Tenha uma boa prova! 😀</p>
                }
                <SyncLoader />
            </div>
        </div>
    );
}

export default Loading;
