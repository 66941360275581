import React, { useRef, useEffect, useCallback, useState, useContext } from 'react';
import { useSpring, animated } from 'react-spring';

import axios from 'axios';

import { appContext } from '../../../context';

import backendAPI from '../../../services/backendAPI';
import { fetchCourses } from '../../../utils/fetchCourses';

import { Typography, Button, FormLabel, Checkbox } from '@material-ui/core';

import {
    Background,
    CloseModalButton,
    ModalContent,
    ModalWrapper,
    CoursesList,
    Course,
    ShareButton,
} from './styles'

export default function({ showModal, setShowModal, bank, getBanks }) {

    const { googleCredentials, courses, setCourses, signOut } = useContext(appContext);

    const [bankCourses, setBankCourses] = useState(bank.courses);
    const [saving, setSaving] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const modalRef = useRef();

    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? 'translateY(0%)' : 'translateY(-100%)'
    });

    const closeModal = e => {
        if(modalRef.current === e.target) {
            setShowModal(false)
        }
    }

    const keyPress = useCallback(e => {
        if(e.key === 'Escape' && showModal) {
            setShowModal(false)
        }
    }, [setShowModal, showModal])

    const handleSave = async () => {
        setSaving(true);
        const response = await backendAPI(googleCredentials).patch('/banco/share', {
            bank: bank._id,
            courses: bankCourses,
        }).then(() => {
            getBanks();
            setSaving(false);
            setShowModal(false);
        });
    }

    const handleCheck = async (course, checked) => {
        const courses = [ ...bankCourses ];

        if (checked) courses.push(course);
        else courses.splice(courses.indexOf(course), 1);

        setBankCourses(courses);
    }


    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress)
    }, [keyPress])

    useEffect(() => {
        async function getCourses() {
            const response = await fetchCourses(googleCredentials, signOut)
            setCourses(response)
        }

        if (courses[0] === 'searching' || !courses) getCourses();
    }, []);

    useEffect(() => {
        setBankCourses(bank.courses);
    }, [bank]);

    useEffect(() => {
        if (JSON.stringify(bankCourses) === JSON.stringify(bank.courses)) setHasChanges(false);
        else setHasChanges(true);
    }, [bankCourses]);

    return (
        <>
        {showModal ? (
            <Background ref={modalRef} onClick={closeModal}>
                <animated.div style={animation}>
                <ModalWrapper showModal={showModal}>
                <ModalContent>
                    <Typography variant="h5">Turmas:</Typography>
                    <CoursesList>
                        <tbody>
                            {
                                courses[0] === 'searching' ? <tr>Carregando turmas...</tr> :
                                courses.map((course, index) => (
                                    bank.courses[0] === course.id
                                    ? <tr key={course.id}>
                                        <Course original={true} active={false} index={index}>
                                            <Checkbox id={course.id} checked={true} readOnly={true}></Checkbox>
                                            <FormLabel htmlFor={course.id}>{ course.name } (Original)</FormLabel>
                                        </Course>
                                    </tr>
                                    : <tr key={course.id} >
                                        <Course active={true} index={index}>
                                            <Checkbox id={course.id} onChange={(event) => handleCheck(course.id, event.target.checked)} defaultChecked={bank.courses.includes(course.id)}></Checkbox>
                                            <FormLabel htmlFor={course.id}>{ course.name }</FormLabel>
                                        </Course>
                                    </tr>
                                ))
                            }
                        </tbody>

                    </CoursesList>

                    <ShareButton
                        active={hasChanges && !saving}
                        onClick={() => hasChanges && !saving ? handleSave() : null}
                    >
                        {
                            saving ? 'Salvando...' : 'Salvar'
                        }
                    </ShareButton>

                </ModalContent>
                <CloseModalButton aria-label='Close modal' onClick={() => setShowModal(prev => !prev)} />
                </ModalWrapper>
            </animated.div>
            </Background>
        ) : null}
    </>
    )
};
