import { cloneDeep } from "lodash";

export default function sortTests (testsArray, sortMethod) {
  const testsCopy = cloneDeep(testsArray);

  if (!testsCopy) return;

  if (sortMethod === 'alphabetic') {
    if (testsCopy) {
      testsCopy.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    }
  } else {
    testsCopy.sort((a, b) => {
      return (a.originalIndex < b.originalIndex) ? -1 : (a.originalIndex > b.originalIndex) ? 1 : 0;
    });
  }

  return testsCopy;
}
