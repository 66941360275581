import React, { useContext, useState } from 'react';
import { Button, Card, CardActions, CardContent, Grid, Modal, Typography, Box } from "@material-ui/core";
import { Delete, Done } from "@mui/icons-material";
import { useHistory } from 'react-router';
import backendAPI from '../../services/backendAPI';
import { appContext } from '../../context';

export default function TestCard({ test, courseId }) {

  const history = useHistory();

  const { googleCredentials } = useContext(appContext);

  const [isDeleting, setIsDeleting] = useState(false);
  const [showModalDeleteTest, setShowModalDeleteTest] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleCloseModalDelete = () => setShowModalDeleteTest(false);

  const deleteTest = async () => {
    if (isDeleting) return;

    setIsDeleting(true);
    try {
      await backendAPI(googleCredentials).delete(`/provas?id=${test.googleCourseWorkId}&course=${courseId}`);
      setDeleted(true);
    } catch (err) {
      alert('Erro ao apagar, tente novamente')
    }
    setIsDeleting(false);
    setShowModalDeleteTest(false);
  }

  const deleteModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
  }

  if (deleted) return null;

  return (
    <>
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        <Card>
          <CardContent>
            <Typography variant="body1" sx={{ fontSize: 16, fontWeight: '500' }}>{test.name.toUpperCase()}</Typography>
            <Typography variant="subtitle1">
              {
                test.dueDate ? 'Prazo: ' + new Date(test.dueDate).toLocaleDateString() : 'Sem prazo de entrega'
              }
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="inherit" onClick={() => history.push(`/provas/${test._id}/respostas?turma=${courseId}`)}>
              <Done sx={{ mr: 1 }} color="success" />
              Entregues ({test.finishedSessions})
            </Button>
            <Button size="small" color="inherit" onClick={() => setShowModalDeleteTest(true)}>
              <Delete sx={{ mr: 1 }} color="error" />
              Excluir
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Modal
        open={showModalDeleteTest}
        onClose={handleCloseModalDelete}
      >
        <Box sx={deleteModalStyle}>
          <Typography variant="h6">Tem certeza que deseja apagar a prova?</Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>Isso apagará todas as notas referentes ao teste</Typography>
          <Button variant="contained" color="error" sx={{ mr: 2 }} onClick={deleteTest}>
            {isDeleting ? 'Excluindo...' : 'Excluir'}
          </Button>
          <Button variant="outlined" color="info" onClick={handleCloseModalDelete}>Cancelar</Button>
        </Box>
      </Modal>
    </>
  );

}
