import { cloneDeep } from "lodash";

export default function sortQuestionsBanks(banksArray, sortMethod) {
  const banksCopy = cloneDeep(banksArray);

  if (!banksCopy) return;

  if (sortMethod === 'alphabetic') {
    if (banksCopy) {
      banksCopy.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    }
  } else {
    banksCopy.sort((a, b) => {
      return (a.originalIndex < b.originalIndex) ? -1 : (a.originalIndex > b.originalIndex) ? 1 : 0;
    });
  }

  return banksCopy;
}
