import React, { useRef, useEffect, useCallback, useState, useContext } from 'react';
import { useSpring, animated } from 'react-spring';

import axios from 'axios';

import { appContext } from '../../../context';

import styled from 'styled-components';

import { MdClose } from 'react-icons/md';
import backendAPI from '../../../services/backendAPI';

const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalWrapper = styled.div`
    width: 600px;
    padding: 30px 50px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: start;
    z-index: 10;
    border-radius: 10px;
`;

const ModalContent = styled.div`
    color: #141414;
    h1 {
        display: flex;
        text-align: top;
        position: relative;
        font-size: 15pt;
    }
    div {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 30px;
    }
    button {
        display: inline;
        padding: 10px;
        width: 80px;
        border-radius: 10px;
        color: #fff;
        border: none;
        font-size: 10pt;
        box-shadow: 1px 1px 2px black;
        cursor:pointer;
        transition: 0.5s;
    }
    #delete {
        background: red;
        margin-left: 10px;
    }
    #cancel {
        background: rgba(0, 0, 0, 0.4);
    }
`;

const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;

export default function({ showConfirmation, setShowConfirmation, questionId, getQuestions }) {

    const { googleCredentials } = useContext(appContext);

    const modalRef = useRef();
    const [deleting, setDeleting] = useState(false);

    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showConfirmation ? 1 : 0,
        transform: showConfirmation ? 'translateY(0%)' : 'translateY(-100%)'
    });

    const closeModal = e => {
        if(modalRef.current === e.target) {
            setShowConfirmation(false)
        }
    }

    const keyPress = useCallback(e => {
        if(e.key === 'Escape' && showConfirmation) {
            setShowConfirmation(false)
        }
    }, [setShowConfirmation, showConfirmation])

    const handleDelete = async () => {
        setDeleting(true);
        try {
            await backendAPI(googleCredentials).delete(`/questao?id=${questionId}`);
        } catch (err) {

            if (err.response.data.error === 'This questions is being used on a test session') {
                alert('Você não pode apagar essa questão, pois ela está sendo utilizada em uma prova');
            } else {
                alert('Ocorreu um erro ao apagar a questão');
            }

        }
        getQuestions();
        setShowConfirmation(false);
        setDeleting(false);
    }

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress)
    }, [keyPress])

    return (
        <>
        {showConfirmation ? (
            <Background ref={modalRef} onClick={closeModal}>
                <animated.div style={animation}>
                <ModalWrapper showModal={showConfirmation}>
                <ModalContent>
                    <h1>Tem certeza que deseja apagar a questão?</h1>

                    <div>
                        <button id="cancel" onClick={() => setShowConfirmation(prev => !prev)}>Cancelar</button>
                        <button id="delete" onClick={handleDelete}>{ deleting ? 'Apagando...' : 'Apagar' }</button>
                    </div>
                </ModalContent>
                <CloseModalButton aria-label='Close modal' onClick={() => setShowConfirmation(prev => !prev)} />
                </ModalWrapper>
            </animated.div>
            </Background>
        ) : null}
    </>
    )
};
