import React from 'react';
import { Alert, Snackbar } from '@material-ui/core';

export default function GenericSnackbar({ children, text, open, onClose, type, position, autoHideDuration = 2000 }) {
  return (
    <Snackbar anchorOrigin={position} open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert onClose={onClose} severity={type} sx={{ width: '100%' }}>
        {children || text}
      </Alert>
    </Snackbar>
  )
}
