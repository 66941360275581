import React, { useContext, useState } from 'react';
import DropdownMenu, { DropdownItem } from './DropdownMenu';
import { GiHamburgerMenu } from 'react-icons/gi';

import SisProvaLogo from '../../../assets/images/logo.svg';
import MenuBar from '../MenuBar';

import { appContext } from '../../../context';
import { useHistory } from 'react-router-dom';


import { Container } from './styles';
import { AppBar, Avatar, Box, IconButton, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import { Divider } from '@mui/material';

function Header({ children }) {

  const [sidebar, setSidebar] = useState(false);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);

  const showSidebar = () => setSidebar(true);

  const { isAuth, googleCredentials, signOut, isTestInterface } = useContext(appContext);

  const history = useHistory();

  const openProfileMenu = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setProfileMenuAnchorEl(null);
  }

  return (
    <Box>
      <AppBar sx={{ position: 'initial', background: 'rgb(25, 156, 25)' }}>
        <Toolbar>
          <IconButton size="large" aria-label="menu" onClick={showSidebar}>
            <MenuIcon sx={{ fill: '#fff' }} />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <img onClick={() => history.push('/')} src={SisProvaLogo} height="80px" width="260px" alt="sisProva" />
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            {children}
            <IconButton
              size="large"
              edge="end"
              aria-haspopup="true"
              color="inherit"
              sx={{ marginRight: 5 }}
              onClick={openProfileMenu}
            >
              <Avatar src={googleCredentials.profileObj.imageUrl} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {
        !isTestInterface && <MenuBar sidebar={sidebar} setSidebar={setSidebar} />
      }
      <Menu
        anchorEl={profileMenuAnchorEl}
        open={Boolean(profileMenuAnchorEl)}
        onClose={handleCloseProfileMenu}
      >
        <Box pb={2} pt={1} pr={2} pl={2}>
          <Typography fontWeight='bold'>{googleCredentials.profileObj.name} {googleCredentials.profileObj.familyName}</Typography>
          <Typography variant='subtitle1' color="#777">{googleCredentials.profileObj.email}</Typography>
        </Box>
        <Divider sx={{ mb: 1 }} />
        {
          !isTestInterface &&
          <MenuItem onClick={() => history.push('/')}>
            <Typography color='primary'>Cursos</Typography>
          </MenuItem>
        }
        <Divider />
        <MenuItem onClick={signOut}>
          <Typography color='error' fontWeight='bold'>Sair</Typography>
        </MenuItem>
      </Menu>
    </Box>
    // <Container>



    //   <div className="left">

    //     {!isTestInterface && <GiHamburgerMenu className='menu-toggle' size="30" onClick={showSidebar} />}

    //     <img onClick={() => history.push('/')} src={SisProvaLogo} height="80px" width="260px" alt="sisProva" />
    //   </div>
    //   <div className='right'>
    //     {
    //       children
    //     }
    // {
    //   isAuth
    //     ? <>
    //       <DropdownMenu image={`${googleCredentials.profileObj.imageUrl}`} userData={googleCredentials.profileObj}>
    //         {
    //           isTestInterface == false
    //             ? <>
    //               <DropdownItem onClick={() => history.push('/')}>Cursos</DropdownItem>
    //               {/* <DropdownItem onClick={() => history.push('/bancos')}>Bancos de Questões</DropdownItem> */}
    //             </>
    //             : null
    //         }
    //         <DropdownItem onClick={signOut}><strong className="logout">Sair</strong></DropdownItem>
    //       </DropdownMenu>
    //     </>
    //     : null
    // }
    //   </div>
    // </Container>
  );

}

export default Header;
