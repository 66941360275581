import React, { useState } from "react";
import ImageCompress from 'quill-image-compress';
import './highlight.js';

import katex from 'katex';
import 'katex/dist/katex.min.css';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

window.katex = katex;

export default function Editor({ value, setValue }) {

    Quill.register('modules/imageCompress', ImageCompress);

    const toolbar = [
        [{ 'font': [] }],
        [{ 'align': [false, 'right', 'justify', 'center'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'size': [] }],
        [{ 'header': 1 }, { 'header': 2 }],
        ['link', 'image', 'video'],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        ['blockquote', 'code-block', 'formula']
    ]

    return (
        <ReactQuill theme="snow" value={value} onChange={setValue} modules={{
            toolbar,
            syntax: true,
            imageCompress: {
                quality: 0.8,
                imageType: 'image/jpeg',
                debug: true,
            }
        }} />
    );
}
