import React, { useRef, useEffect, useCallback, useState, useContext } from 'react';
import { useSpring, animated } from 'react-spring';

import backendAPI from '../../../services/backendAPI';
import googleAPI from '../../../services/googleAPI';

import { appContext } from '../../../context';

import { Background, ModalWrapper, ModalContent, CloseModalButton, Button } from './styles'
import sortTests from '../../ListCourseBanks/utils/sortTests';

export const PopUpCreateTest = ({ showModal, setShowModal, banks, courseId, sort }) => {
  const { googleCredentials, tests, setTests, sortMethod } = useContext(appContext);

  const [bank, setBank] = useState(banks[0]);
  const [topics, setTopics] = useState([]);
  const [name, setName] = useState('');
  const [numberOfQuestions, setNumberOfQuestions] = useState(1);
  const [categories, setCategories] = useState({});
  const [value, setValue] = useState(1);
  const [topic, setTopic] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const [dueTime, setDueTime] = useState();
  const [dueDate, setDueDate] = useState();
  const [timeCountdown, setTimeCountdown] = useState();
  const [showDueDateInputs, setShowDueDateInputs] = useState(false);
  const [showCategoriesInputs, setShowCategoriesInputs] = useState(false);
  const [showTimeCountdownInput, setShowTimeCountdownInput] = useState(false);
  const [creatingTest, setCreatingTest] = useState(false);
  const [creatingTopic, setCreatingTopic] = useState(false);
  const [bankQuestions, setBankQuestions] = useState({});
  const [availableCategories, setAvailableCategories] = useState([]);

  const modalRef = useRef();
  const newTopicInput = useRef();

  useEffect(() => {
    setBank(banks[0] || { questions: [] });
  }, [banks]);

  useEffect(() => {
    getTopics();
  }, [courseId]);

  useEffect(() => {

    async function getBankQuestions() {

      if (!showCategoriesInputs) return;

      try {

        const { data } = await backendAPI(googleCredentials).get(`/banco/${bank._id}`);
        setBankQuestions(prev => {
          return {
            ...prev,
            [bank._id]: data.questions,
          }
        });

        const tags = {}

        data.questions.map(question => {
          if (tags[question.tag]) tags[question.tag] += 1;
          else if (!question.tag && !tags['Sem categoria']) tags['Sem categoria'] = 1;
          else if (!question.tag) tags['Sem categoria'] += 1;
          else tags[question.tag] = 1;
        });

        setAvailableCategories(tags);

      } catch (err) {
        alert('Ocorreu um erro ao buscar as categorias disponíveis');
      }

    }

    getBankQuestions();


  }, [showCategoriesInputs, bank]);

  const getTopics = async () => {
    try {
      const { data } = await googleAPI(googleCredentials).get(`https://classroom.googleapis.com/v1/courses/${courseId}/topics`);
      setTopics(data.topic);
    } catch (err) {
      setTopics([]);
    }
  }

  const handleSubmit = async () => {

    if (creatingTest) return;

    setCreatingTest(true);

    try {
      const response = await backendAPI(googleCredentials).post('/provas', {
        name,
        course: courseId,
        bank: bank._id,
        value,
        numberOfQuestions: showCategoriesInputs ? getTotalQuestions() : Number(numberOfQuestions),
        tags: showCategoriesInputs ? categories : undefined,
        timeToDo: timeCountdown,
        dueDate: new Date(`${dueDate} ${dueTime}`),
        published: !isDraft,
        topicId: topic,
      });

      const currentClassUpdatedTestsArray = [
        { ...response.data, finishedSessions: 0 },
        ...tests[courseId]
      ];

      setTests(prev => {
        return { ...prev, [courseId]: sortTests(currentClassUpdatedTestsArray, sortMethod) };
      });

    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
    }
    setShowModal(false);
    setCreatingTest(false);
  }

  const handleBankChange = (event) => {
    const id = event.target.value;
    const [bankData] = banks.filter((bank) => {
      if (bank._id === id) return true
      return false
    })
    setBank(bankData);
  }

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? 'translateY(0%)' : 'translateY(-100%)'
  });

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  }

  const keyPress = useCallback(e => {
    if (e.key === 'Escape' && showModal) {
      setShowModal(false);
    }
  }, [setShowModal, showModal]);

  const handleToggleDueDate = () => {
    setDueDate();
    setDueTime(showDueDateInputs ? undefined : '23:59');
    setShowDueDateInputs(prev => !prev);
  }

  const handleToggleTimeCountdown = () => {
    setTimeCountdown(showTimeCountdownInput ? undefined : 50);
    setShowTimeCountdownInput(prev => !prev);
  }

  const handleCreateNewTopic = async (name) => {

    if (creatingTopic) return;

    setCreatingTopic(true);

    try {

      const response = await googleAPI(googleCredentials).post(`https://classroom.googleapis.com/v1/courses/${courseId}/topics`, {
        name,
      });

      alert('Tópico criado com sucesso');

      setTopics(prev => [...prev, response.data]);

      setTopic(response.data.topicId);

    } catch (err) {
      alert('Erro ao criar tópico, tente novamente ou crie diretamente no Google Sala de Aula');
    }

    setCreatingTopic(false);

  }

  const getTotalQuestions = () => {

    return Object.keys(categories).reduce((sum, category) => {
      return sum + categories[category];
    }, 0);

  }

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal ? (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div style={animation}>
            <ModalWrapper showModalQuestions={setShowModal}>
              <ModalContent>
                <h1>Crie uma prova</h1>

                <div className="input-block">
                  <h2>Nome da prova:</h2>
                  <input autoFocus type="text" value={name} onChange={(event) => setName(event.target.value)} />
                </div>

                <div className="input-block">
                  <h2>Banco de questões</h2>
                  <select onChange={handleBankChange}>
                    {
                      banks && banks.map((bank) => (
                        <option key={bank._id} value={bank._id}>{bank.name}</option>
                      ))
                    }
                  </select>
                </div>

                {
                  !showCategoriesInputs &&
                  <div className="input-block">
                    <h2>Número de questões (categorias aleatórias)</h2>
                    <input type="number" max={bank.questions.length} min="1" value={numberOfQuestions}
                      onChange={(event) => setNumberOfQuestions(event.target.value)} />
                  </div>
                }

                <div className="input-toggle">
                  <h2>Categorias</h2>

                  {
                    showCategoriesInputs &&
                    <table>
                      <thead>
                        <tr>
                          <td>Categoria</td>
                          <td>Numero de questões</td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          Object.keys(availableCategories).map?.((category, index) => (
                            <tr key={index}>
                              <td>{category}</td>
                              <td>
                                <input
                                  type="number"
                                  value={categories[category] || 0}
                                  min="0"
                                  onChange={event => {
                                    event.persist();
                                    if (Number(event.target.value) > availableCategories[category]) return;
                                    setCategories(prev => {
                                      return {
                                        ...prev,
                                        [category]: Number(event.target.value)
                                      }
                                    })
                                  }}
                                />
                              </td>
                            </tr>
                          ))
                        }
                        <tr className="bold">
                          <td>Numero de questões:</td>
                          <td>{getTotalQuestions()}</td>
                        </tr>
                      </tbody>
                    </table>

                  }
                  <button className='btn' onClick={() => setShowCategoriesInputs(prev => !prev)}>
                    {showCategoriesInputs ? 'Remover ' : 'Selecionar '} categorias
                  </button>

                </div>

                <div className="input-block">
                  <h2>Pontos</h2>
                  <input type="number" value={value} onChange={(event) => setValue(event.target.value)} />
                </div>

                <div className="input-block">
                  <h2>Tópico</h2>
                  {
                    topic === 'new' ?

                      <div className='new-topic'>
                        <input ref={newTopicInput} placeholder='Nome do tópico...'></input>

                        <button className='btn-create' max={100} onClick={event => handleCreateNewTopic(newTopicInput.current.value)}>
                          {creatingTopic ? 'Criando...' : 'Criar'}
                        </button>
                        <button className='btn-cancel' onClick={() => setTopic('')}>Cancelar</button>
                      </div>

                      : <select value={topic} onChange={event => setTopic(event.target.value)}>
                        <option className='new-topic-option' value='new'>Criar novo tópico</option>
                        <option value={''}>Nenhum</option>
                        {
                          topics && topics.map(t => (
                            <option key={t.topicId} value={t.topicId}>{t.name}</option>
                          ))
                        }
                      </select>

                  }
                </div>

                <div className="input-toggle">
                  <h2>Data de Entrega</h2>
                  {
                    showDueDateInputs
                      ?
                      <>
                        <div className="input-block toggle-item">
                          <input type="date" className="pointer" value={dueDate} onChange={(event) => setDueDate(event.target.value)}></input>
                        </div>
                        <div className="input-block toggle-item">
                          <h2>Horário Limite</h2>
                          <input type="time" className="pointer" value={dueTime} onChange={(event) => setDueTime(event.target.value)} />
                        </div>
                      </>
                      : null
                  }
                  <button className='btn' onClick={handleToggleDueDate}>
                    {showDueDateInputs ? 'Remover ' : 'Adicionar '} data de entrega
                  </button>

                </div>

                <div className="input-toggle">
                  <h2>Tempo de realização (EM MINUTOS)</h2>
                  <div className="input-block toggle-item">
                    {
                      showTimeCountdownInput
                        ? <input type="number" className="pointer" step="10" value={timeCountdown} onChange={(event) => setTimeCountdown(event.target.value)} />
                        : null
                    }
                  </div>
                  <button className='btn' onClick={handleToggleTimeCountdown}>
                    {showTimeCountdownInput ? 'Remover ' : 'Adicionar '} tempo de realização
                  </button>
                </div>

                <div className="input-block isDraft">
                  <input id="draft" className="checkbox" type="checkbox" checked={isDraft} onChange={() => setIsDraft(prev => !prev)} />
                  <label htmlFor="draft" className="pointer">Rascunho?</label>
                </div>

                <Button onClick={handleSubmit}>{creatingTest ? 'Criando prova, aguarde...' : 'Criar'}</Button>
              </ModalContent>
              <CloseModalButton aria-label='Close modal' onClick={() => setShowModal(prev => !prev)} />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
