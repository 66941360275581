export default function secondsToTime(secs, interval) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    // Corrige formatação de horário 21:60 para 22:00, por exemplo
    if (seconds == 60) {
        minutes += 1;
        seconds = 0;
    }

    if (hours <= 0 && minutes <= 0 && seconds <= 0) {
        hours = 0;
        minutes = 0;
        seconds = 0;
        if (interval) clearInterval(interval);
    }

    return [hours, minutes, seconds].map(number => number.toLocaleString('pt-BR', {
        minimumIntegerDigits: 2,
        useGrouping: false
    }));
}
