import React from 'react';

import styled from 'styled-components';

import { MdClose } from 'react-icons/md';

export const Background = styled.div`
    * {
        // border: 1px solid red;
    }
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalWrapper = styled.div`
    width: 800px;
    height: 90vh;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    overflow: auto;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 10;
    border-radius: 10px;
`;

export const ModalContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    line-height: 1.8rem;
    color: #141414;
    padding: 30px;
    .input-block {
        width: 80%;
        margin: 20px;

        .new-topic-option {
            font-weight: bold;
        }

        .new-topic {
            display: flex;
            justify-content: space-between;
            align-items: center;
            input {
                width: 70%;
            }

            button {
                width: 14%;
                padding: 11px;
                color: #FFF;
                font-weight: bold;
                border: 0;
                box-shadow: 2px 2px 5px #aaa;
                cursor: pointer;
                border-radius: 5px;
            }

            .btn-create {
                background: var(--secondary);
                transition: .2s;
                :hover {
                    background-color: #164e97;
                }
            }

            .btn-cancel {
                background: #ff2525;
                transition: .2s;
                :hover {
                    background: #d23b3b;
                }
            }
        }
    }
    .half-size {
        width: 50%;
    }
    h1 {
        display: flex;
        text-align: top;
        position: relative;
        font-size: 15pt;
        margin: 15px;
    }
    h2 {
        padding-bottom: 20px;
    }
    h3 {
        justify-content: center;
        align-items: center;
        font-size: 10pt;
        margin: 25px;
    }
    h5 {
        text-align: center;
        align-items: center;
        font-size: 9pt;
        margin: 5px;
    }
    p {
        text-align: center;
        font-size: 10pt;
        padding: 20px;
        margin-bottom: 10px;
    }
    .btn {
        width: 90%;
        height: 30px;
        cursor: pointer;
    }
    input, textarea, select {
        width: 100%;
        padding: 10px;
    }
    textarea {
        width: 550px;
        height: 200px;
        resize: vertical;
        margin: 0;
        padding: 20px;
        white-space: pre-wrap;
    }
    .optionBox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .optionBox :is(span, input){
        font-size: 13pt;
        padding: 0 10px;
    }
    .optionBox input {
        height: 18px;
        width: 18px;
    }
    .optionBox div {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
    }
    .optionBox div label {
        font-size: 10pt;
        display: block;
    }
    .answerQuestion {
        width: 550px;
        height: 45px;
    }
    .isDraft {
        display: flex;
    }
    .isDraft input {
        width: 20px !important;
        height: 20px !important;
        margin-right: 10px;
    }
    .isDraft label {
        font-size: 16px;
        font-weight: bold;
    }
    .input-toggle {
        width: 80%;
        border: 1px solid #777;
        padding: 20px;
        margin: 20px;
        button {
            width: 100%;
        }
    }
    .toggle-item {
        width: 100%;
        margin: 0 0 20px 0;
    }
    .pointer {
        cursor: pointer;
    }

    .bold {
        font-weight: bold;
    }

    table {
        font-size: 10pt;
        thead {
            font-weight: bold;
        }
        td {
            padding: 10px 100px;
            padding-left: 0;
        }
        input {
            padding: 5px;
        }
    }

`;

export const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;

export const Button = styled.div`
    cursor: pointer;
    background: green;
    width: 80%;
    height: 50px;
    text-align: center;
    padding-top: 15px;
    border: none;
    border-radius: 5px;
    margin: 20px;
    color: #fff;
    font-size: 14pt;
`;
