import React, { useState, useEffect, useContext } from 'react';
import { CSVDownload, CSVLink } from 'react-csv';
import { useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { MdKeyboardArrowDown, MdCheck, MdCancel, MdClose } from 'react-icons/md';
import { BsCloudUpload, BsFillExclamationCircleFill, BsClockFill, BsTable } from 'react-icons/bs';
import { FaCalendarTimes, FaClock } from 'react-icons/fa';

import 'react-quill/dist/quill.snow.css';

import Header from '../components/Header';
import Statistics from './statistics';

import { appContext } from '../../context';

import { Input, TextField, Typography } from '@material-ui/core';

import Loading from '../Loading';
import backendAPI from '../../services/backendAPI';
import googleAPI from '../../services/googleAPI';

import {
  Answers,
  Button,
  Comment,
  Container,
  Details,
  List,
  ListItem,
  Options,
  Option,
  Overview,
  Question,
} from './styles';
import { Box } from '@mui/system';
import { Divider } from '@mui/material';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


function ListStudentSubmissions({ location }) {

  const queryParams = useQuery();

  const { googleCredentials } = useContext(appContext);

  const history = useHistory();

  const testId = location.pathname.split('/')[2];

  const [submissions, setSubmissions] = useState(['searching']);
  const [test, setTest] = useState([]);
  const [students, setStudents] = useState(['searching']);
  const [nextPageToken, setNextPageToken] = useState();
  const [gradesTable, setGradesTable] = useState([]);
  const [publishingAllGrades, setPublishingAllGrades] = useState(false);
  const [publishingGrades, setPublishingGrades] = useState([]);
  const [deletingSubmissions, setDeletingSubmissions] = useState([]);

  let timeout = setTimeout(() => {

  }, 0);

  useEffect(() => {
    (async () => {
      /*Comunica com o backend e pega o 'ID' da prova*/
      backendAPI(googleCredentials).get(`/provas/${testId}/report`)
        .then(({ data }) => {
          setTest(data.test);
          const submissionsStatuses = new Array(data.submissions.length).map((_, index) => {
            if (data.submissions[index].publishedGrade === data.submissions[index].grade) return 'published';

            return 'pending';
          });

          setPublishingGrades(submissionsStatuses);
          setSubmissions(data.submissions);
        });

      const courseId = queryParams.get('turma');

      async function getStudents() {

        let studentsArray = []
        let nextPageToken = '';

        while (true) {
          /* Comunica com o backend, desestruturando e pegando 'data' de dentro dele */
          const { data } = await googleAPI(googleCredentials).get(`https://classroom.googleapis.com/v1/courses/${courseId}/students?${nextPageToken ? 'pageToken=' + nextPageToken : ''}`)

          studentsArray = [...studentsArray, ...data.students];
          nextPageToken = data.nextPageToken;

          if (!data.nextPageToken) break;
        }

        setStudents(studentsArray)
      }

      getStudents();

    })();
  }, []);

  useEffect(() => {

    generateGradeTable();

  }, [students, submissions]);


  //Exibe os detalhes da submissão
  const handleShowDetail = (submissionId) => {
    const submissionsCopy = [...submissions];

    submissionsCopy.forEach(submission => {
      if (submission.submissionId === submissionId) submission.showDetails = !submission.showDetails;
    });

    setSubmissions(submissionsCopy);
  }

  //Pega o nome do aluno
  const getStudentName = (userId) => {
    const userData = students.filter(student => {
      if (student.userId === userId) return true;

      return false;
    });

    return userData[0]?.profile?.name?.fullName;
  }

  //Faz o arredondamento das notas em questões abertas
  function roundNumber(num, scale) {
    if (!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if (+arr[1] + scale > 0) {
        sig = "+";
      }
      return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
  }

  //Atualiza a 'grade'
  const updateGrade = async (questionId, grade, sessionId) => {
    clearTimeout(timeout);

    timeout = setTimeout(async () => {

      //Envia a atualização dos dados para o backend, método PATCH
      const { data } = await backendAPI(googleCredentials).patch(`/provas/sessao/${sessionId}/notas`, {
        questionId,
        grade: Number(grade),
      });

      const submissionsCopy = [...submissions];

      submissionsCopy.forEach(currentSubmission => {
        if (currentSubmission._id === sessionId) {
          currentSubmission.grade = roundNumber(data.grade, 2);

          currentSubmission.questions.forEach(question => {
            console.log(question)
            if (question.question._id === questionId) {
              question.grade = grade;
            }
          });
        }
        setSubmissions(submissionsCopy);
      });

    }, 500);
  }

  //lança todas as notas de forma assíncrona
  const handlePublishAllGrades = async () => {

    if (publishingAllGrades) return;

    const grades = {};
    submissions.forEach(submission => {
      if (submission.grade !== submission.publishedGrade && !deletingSubmissions.includes(submission._id))
        grades[submission._id] = submission.grade;
    });

    if (Object.keys(grades).length === 0) return;

    setPublishingAllGrades(true);

    try {

      await backendAPI(googleCredentials).post(`/provas/${testId}/notas/publicar`, {
        grades,
      });

      const publishingGradesCopy = [...publishingGrades];
      const submissionsCopy = { ...submissions };

      setSubmissions(prev => prev.map(submission => {
        if (Object.keys(grades).includes(submission._id)) submission.publishedGrade = submission.grade;
        return submission;
      }));

      Object.keys(grades).forEach((submission, index) => {
        publishingGradesCopy[index] = 'published';
        return submission;
      });

      setPublishingGrades([...publishingGradesCopy]);

      alert('Notas lançadas!');

    } catch (err) {
      alert('Ocorreu um erro ao publicar as notas do aluno, se o problema persistir tentar lançar as notas individualmente');
    }
  }

  //Faz a publicação de uma única 'grade'
  const handlePublishSingleGrade = async (sessionId, userId, index) => {

    if (
      submissions[index].grade === submissions[index].publishedGrade ||
      deletingSubmissions.includes(submissions[index]._id) ||
      publishingGrades[index] === 'publishing'
    ) return;

    const publishingGradesCopy = [...publishingGrades];

    const previousStatus = publishingGrades[index];

    publishingGradesCopy[index] = 'publishing';

    setPublishingGrades(publishingGradesCopy);

    try {
      const { data } = await backendAPI(googleCredentials).post(`/provas/${testId}/notas/publicar`, {
        grades: {
          [sessionId]: Number(submissions[index].grade),
        },
      });

      publishingGradesCopy[index] = 'published';
      setPublishingGrades([...publishingGradesCopy]);

      const submissionsCopy = [...submissions];
      submissionsCopy[index].publishedGrade = submissions[index].grade;
      setSubmissions([...submissionsCopy]);

    } catch (err) {
      publishingGradesCopy[index] = previousStatus;
      setPublishingGrades([...publishingGradesCopy]);
      alert(`Erro ao publicar nota do aluno ${getStudentName(userId)}`);
    }

  }

  const handleDeleteSubmission = async (submissionId) => {

    if (!window.confirm('Tem certeza que deseja anular a prova? Após isso as respostas do aluno são irrecuperáveis!')) return

    if (deletingSubmissions.includes(submissionId)) return;

    setDeletingSubmissions(prev => [...prev, submissionId]);

    try {

      const { data } = await backendAPI(googleCredentials).delete(`/provas/sessao?session=${submissionId}`);

      // Remove prova da lista
      const submissionsCopy = submissions.filter(submission => submission._id !== submissionId);

      setSubmissions(submissionsCopy);

    } catch (err) {
      alert('Erro ao apagar prova do aluno');
    }

    const deletingCopy = deletingSubmissions;
    deletingCopy.splice(submissionId, 1)

    setDeletingSubmissions(deletingCopy);

  }

  /*  const calculateCorrectnessPercentage = ({ question, answer }) => {

        if (question.type === 'singleChoice') {

            const correct = question.options.reduce((accumulator, option) => {
                if (option.isCorrect) return option._id;
                return accumulator;
            }, undefined);

            if (answer[0] === correct) return 100;

            return 0;

        } else {
            if (answer.length === 0) return 0;

            const points = question.options.reduce((accumulator, currentOption, index) => {
                if (answer.includes(currentOption._id) === currentOption.isCorrect) accumulator += 1;

                return accumulator;
            }, 0);

            return Math.round((points / question.options.length) * 100)

        }
    }
*/
  const lateStatus = (submission) => {

    let code = 0;

    if (test.timeToDo || test.dueDate) {

      const testDuration = new Date(submission.endTime).getTime() - new Date(submission.startTime).getTime()

      if (new Date(submission.endTime).getTime() > new Date(test.dueDate).getTime()) code = 1;
      if (testDuration > test.timeToDo * 60 * 1000) {

        if (code == 0) code = 2;
        else code = 3;

      };

    }

    return code;

  }

  function testDuration(submission) {
    const endTime = new Date(submission.endTime).getTime();
    const startTime = new Date(submission.startTime).getTime();

    return Math.ceil((endTime - startTime) / 1000 / 60)
  }

  function generateGradeTable() {

    const data = [['Aluno(a)', 'Nota']];

    submissions.forEach(submission => {
      data.push([getStudentName(submission.user), String(submission.grade).replace('.', ',')]);
    });


    setGradesTable(data);

  }

  function getHelperText(grade, value, numberOfQuestions) {
    const valuePerQuestion = Number((value / numberOfQuestions).toFixed(2));
    if (isNaN(grade)) return 'Nota pendente';
    else if (grade > valuePerQuestion) return 'Maior que o valor da questão';
    else return 'Nota salva'
  }

  if (submissions?.[0] === 'searching' || students?.[0] === 'searching') return <Loading />

  return (
    <>
      <Header />

      <Container>

        <Statistics submissions={submissions} />
        <header>
          <Typography variant="h6">Entregues</Typography>
          <div>
            {
              submissions.length > 0
                ? <Button className="exportTable">
                  <div>
                    <BsTable color="white" />
                    <CSVLink data={gradesTable || []} separator={";"} filename={`${test.name}.csv`}>Baixar planilha de notas</CSVLink>
                  </div>
                </Button>
                : null
            }
            {
              submissions.length > 0
                ? <Button
                  active={!publishingAllGrades}
                  onClick={() => handlePublishAllGrades()}
                >
                  {publishingAllGrades ? 'Liberando correção, aguarde...' : 'Liberar todas as correções'}
                </Button>

                : null
            }
          </div>
        </header>

        {
          submissions.length === 0 ? <Typography variant="body1">{"Nenhuma atividade entregue por enquanto :("}</Typography> : null
        }

        <List>
          {
            submissions.map((submission, index) => (
              <ListItem key={submission.submissionId} id={submission.submissionId}>
                <Overview>
                  <h2 onClick={() => handleShowDetail(submission.submissionId)}>
                    {getStudentName(submission.user)}
                    <span>{new Date(submission.endTime).toLocaleDateString('pt-BR')} às {new Date(submission.endTime).getHours()}:{new Date(submission.endTime).getMinutes()}</span>
                    <span>
                      {

                        lateStatus(submission) === 1 || lateStatus(submission) === 3
                          ? <>
                            <FaCalendarTimes data-tip data-for={`calendar-tooltip-${submission._id}`} color="red" />
                            <ReactTooltip className="tip" id={`calendar-tooltip-${submission._id}`} effect="solid" type="error" place="bottom">
                              <strong>Entregue fora do prazo</strong>
                              <p>Entregue dia {new Date(submission.endTime).toLocaleDateString('pt-BR')} às {new Date(submission.endTime).getHours()}:{new Date(submission.endTime).getMinutes()}</p>
                            </ReactTooltip>
                          </>
                          : null
                      }
                    </span>
                    <span>
                      {
                        lateStatus(submission) === 2 || lateStatus(submission) === 3

                          ? <>
                            <FaClock data-tip data-for={`clock-tooltip-${submission._id}`} color="red" />
                            <ReactTooltip className="tip" id={`clock-tooltip-${submission._id}`} effect="solid" type="error" place="bottom">
                              <strong>Excedeu o tempo de realização</strong>
                              <p>Tempo utilizado: {testDuration(submission)} minutos</p>
                            </ReactTooltip>
                          </>
                          : null
                      }
                    </span>
                  </h2>

                  <Options>
                    <Option onClick={() => handleShowDetail(submission.submissionId)}>
                      <span>{submission.grade.toFixed(2)} / {test.value}</span>
                      {/* <input value={Number(submission.grade).toFixed(2)} onChange={(event) => handleChangeGrade(submission, Number(event.target.value).toFixed(2))} min={0} step={0.5} type='number' />{`/${test.value}`} */}
                    </Option>
                    <Option onClick={() => handlePublishSingleGrade(submission._id, submission.user, index)} color="green">
                      {
                        submission.grade === submission.publishedGrade
                          ? <MdCheck />
                          : <BsCloudUpload size={18} />
                      }
                      <label>
                        {
                          publishingGrades[index] !== 'publishing'
                            ? submission.grade === submission.publishedGrade
                              ? 'Correção liberada'
                              : 'Liberar correção'
                            : 'Liberando correção...'
                        }
                      </label>
                    </Option>
                    <Option onClick={() => handleDeleteSubmission(submission._id)} color="red">
                      <MdCancel />
                      <label>
                        {
                          deletingSubmissions.includes(submission._id)
                            ? 'Anulando prova...'
                            : 'Anular prova'
                        }
                      </label>
                    </Option>
                    <Option onClick={() => handleShowDetail(submission.submissionId)} showDetails={submission.showDetails}>
                      <MdKeyboardArrowDown size={25} />
                    </Option>
                  </Options>
                </Overview>
                {
                  submission.showDetails
                    ? <Details>
                      <h3>Respostas do aluno:</h3>
                      {
                        submission.questions.map((question, index) => (
                          <Question key={question._id}>
                            <div className="ql-container ql-snow">
                              <div className="ql-editor" dangerouslySetInnerHTML={{ __html: question.question.statement }} />
                            </div>
                            {/* <span>Porcentagem de acerto: <strong>{calculateCorrectnessPercentage(question)}%</strong></span> */}
                            {
                              question.question.type === 'open' ?
                                <div>
                                  <Typography fontWeight='bold' variant="body1">Resposta do aluno(a):</Typography>
                                  <Typography variant="body1" className="answer">{question.answer[0] || 'Resposta em branco'}</Typography>
                                  <Divider sx={{ marginTop: 1, marginBottom: 3 }} />
                                  <Typography fontWeight='bold' variant="body1">Nota: </Typography>
                                  <Box sx={{ display: 'flex' }}>
                                    <TextField
                                      a={(() => console.log(question.grade))()}
                                      error={question.grade > Number((test.value / test.numberOfQuestions).toFixed(2))}
                                      color={
                                        question.grade <= Number((test.value / test.numberOfQuestions).toFixed(2))
                                          ? 'success'
                                          : 'error'
                                      }
                                      helperText={getHelperText(question.grade, test.value, test.numberOfQuestions)}
                                      variant="standard"
                                      min={0}
                                      sx={{ width: 100 }}
                                      type="number"
                                      defaultValue={question.grade}
                                      onChange={(event) => updateGrade(question.question._id, event.target.value, submission._id)}
                                    />
                                    <Typography variant="body1">{' /' + (test.value / test.numberOfQuestions).toFixed(2)}</Typography>
                                  </Box>
                                </div> :
                                question.question.options.map(option => (
                                  <Answers key={option._id} incorrect={question.answer.includes(option._id) && !option.isCorrect}>
                                    <input
                                      type={question.question.type === 'singleChoice' ? 'radio' : 'checkbox'}
                                      checked={question.answer.includes(option._id)}
                                      readOnly={true}
                                    />
                                    <div className="ql-container ql-snow">
                                      <div className="ql-editor" dangerouslySetInnerHTML={{ __html: option.text }} />
                                    </div>
                                  </Answers>
                                ))
                            }

                            <PrivateComment index={index} session={submission} question={question} />
                          </Question>
                        ))
                      }
                    </Details>
                    : null
                }
              </ListItem>
            ))
          }

        </List>
      </Container>
    </>
  );
}
export default ListStudentSubmissions;


function PrivateComment({ index, session, question }) {

  const { googleCredentials } = useContext(appContext);

  const [comment, setComment] = useState(question.comment)
  const [visible, setVisible] = useState(question.comment !== '');
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(question.comment);

  const handleSaveComment = async () => {

    if (saving || comment === saved) return;

    setSaving(true);

    const sessionId = session._id;
    const questionId = session.questions[index].question._id

    try {
      const { data } = await backendAPI(googleCredentials).patch(`/prova/sessao/${sessionId}/questao/${questionId}/comment`, {
        comment,
      });
      setSaved(data.comment)
    } catch (err) {
      alert('Erro ao salvar comentário');
    }

    setSaving(false)

  }

  return (
    <Comment>
      <p onClick={() => setVisible(prev => !prev)}>Adicionar comentário particular</p>
      {
        visible
          ? <>
            <textarea defaultValue={question.comment} onChange={event => setComment(event.target.value)} />
            <button onClick={handleSaveComment}>
              {
                saving ? 'Salvando...' :

                  saved === comment ? 'Salvo' : 'Salvar'

              }
            </button>
          </>
          : null
      }
    </Comment>
  )

}
