import axios from 'axios';

const backendAPI = (googleCredentials) => {
  return axios.create({
    //baseURL: 'http://localhost:8000/',
    //baseURL: 'https://200.131.80.159:8000/',
    baseURL: '/api',
    headers: { Authorization: `Bearer ${googleCredentials.tokenId}:${googleCredentials.accessToken}` }
  });
};

export default backendAPI;
