import React from 'react';
import styled from 'styled-components';

import Header from '../components/Header';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 85vh;

    div {
        background: #fff;
        max-width: 600px;
        background: white;
        box-shadow: 2px 2px 10px #d1d1d1;
        padding: 20px;
        border-radius: 20px;
        text-align: center;
        transform: translateY(-20%);
        h1 {
            font-size: 14pt;
            margin-bottom: 10px;
        }
        p {
            font-size: 12pt;
            color: #333;
            text-align: left;
            margin-bottom: 20px;
        }
    }

    table {
        font-size: 12pt;
        border-collapse: collapse;
        width: 100%;
        .head {
            font-weight: bold;
        }
        td {
            border: 1px solid #333;
            padding: 5px 10px;
        }
    }

    button {
        background: var(--secondary);
        width: 100%;
        color: #fff;
        border: 0;
        padding: 10px;
        margin-top: 40px;
        cursor: pointer;
    }
`;

export default function ConfirmInitTest({ test, startTest }) {
   
    return (
        <>
            <Header />
            <Container>
                <div>
                    <h1>{test.name}</h1>
                    { test.timeToDo && <p>Após iniciar, você terá <strong>{test.timeToDo} minutos</strong> para finalizar a prova, não é possível pausar o contador de tempo.</p> }
                    <table>
                        <tbody>
                            <tr className="head">
                                <td>Data de entrega</td>
                                <td>Tempo de realização</td>
                                <td>Número de questões</td>
                            </tr>
                            <tr>
                                <td>
                                    {
                                        test.dueDate
                                            ? new Date(test.dueDate).toLocaleDateString('pt-BR')
                                            : 'Sem prazo de entrega'
                                    }
                                </td>
                                <td>
                                    {
                                        test.timeToDo ? test.timeToDo + ' minutos' : 'Sem tempo limite'
                                    }
                                </td>
                                <td>
                                    {
                                        test.numberOfQuestions
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={startTest}>Iniciar prova...</button>
                </div>
            </Container>
        </>
    );
}
