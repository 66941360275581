function refreshTokenSetup(response, setGoogleCredentials) {

  sessionStorage.setItem('oauth2', JSON.stringify(response));

  let refreshTiming = (response.tokenObj.expires_in || 3600 - (5 * 60)) * 1000;

  const refreshToken = async () => {
    const newAuthResponse = await response.reloadAuthResponse();

    newAuthResponse.accessToken = newAuthResponse.access_token;
    newAuthResponse.access_token = undefined;

    refreshTiming = (newAuthResponse.expires_in || 3600 - (5 * 60)) * 1000;

    const oldCredentials = JSON.parse(sessionStorage.getItem('oauth2'));

    sessionStorage.setItem('oauth2', JSON.stringify({ ...oldCredentials, ...newAuthResponse }));

    setGoogleCredentials({ ...oldCredentials, ...newAuthResponse });

    setTimeout(refreshToken, refreshTiming);

  }

  setTimeout(refreshToken, refreshTiming);

}

export default refreshTokenSetup;
