import React, { useContext } from 'react';
import { GoogleLogin, useGoogleLogin } from 'react-google-login';
import { useHistory } from 'react-router-dom';

import refreshToken from '../../../utils/refreshToken';
import { appContext } from '../../../context';

import GoogleLogo from '../../../assets/images/google-icon.svg';

function LoginButton({ redirectTo }) {

    const history = useHistory();

    const { setIsAuth, setGoogleCredentials } = useContext(appContext);

    const onSuccess = (response) => {
        sessionStorage.setItem('oauth2', JSON.stringify(response));

        setGoogleCredentials(response);
        setIsAuth(true);

        refreshToken(response, setGoogleCredentials);
        history && history.push(redirectTo);
    }

    const onFailure = (err) => {
        alert('Erro no login, certifique-se de fornecer todas as permissões e tente novamente');
    }

    const scopesArray = [
        'https://www.googleapis.com/auth/classroom.courses.readonly',
        'https://www.googleapis.com/auth/classroom.coursework.me',
        'https://www.googleapis.com/auth/classroom.rosters.readonly',
        'https://www.googleapis.com/auth/classroom.courseworkmaterials',
        'https://www.googleapis.com/auth/classroom.coursework.students',
        'https://www.googleapis.com/auth/classroom.topics',
    ];

    const scopes = scopesArray.join(' ');

    const buttonStyle = {
        width: '100%',
        height: '100%',
        padding: '15px',
        borderRadius: '30px',
        background: '#FFF',
        display: 'flex',
        justifyContent: 'center',
        alignItens: 'center',
        margin: 0,
    };

    return (
        <GoogleLogin
            className='button'
            clientId="625991985413-nkuct431idsmte9o9p52bqig4si60bvj.apps.googleusercontent.com"
            buttonText="Entrar"
            scope={scopes}
            render={renderProps => (
                <div onClick={renderProps.onClick} style={buttonStyle}>
                    <img className="googleLogo" alt='google-logo' src={GoogleLogo} />
                    <span>Entrar</span>
                </div>
            )}
            onSuccess={onSuccess}
            onFailure={onFailure}
            isSignedIn={true}
            prompt="consent"
        />
    );

}

export default LoginButton;
