import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    font-size: 100%;
  }
`;

export const Container = styled.div`
  height: 85vh;
  flex-wrap: wrap;
  overflow: auto;
  padding: 0 50px;
  gap: 30px;
  background-color: #f1f1f1;
`;

export const ListContainer = styled.div`
  padding-top: 10px;
  min-height: 90%;
  margin: auto;
`;

export const ListHeader = styled.ul`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .sort-method {
    display: flex;
    align-items: center;
    gap: 20px;
    input, label {
      cursor: pointer;
    }
    input {
      margin-right: 10px;
    }
    label {
      color: #333;
    }
  }
`;

export const SubContainer = styled.div`
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    box-shadow: 0 0 4px #888;
    height: 100%;
    background-color: rgb(217, 245, 217);
`;

export const Tests = styled.div`

`;

export const Button = styled.button`
    border-radius: 30px;
`;

export const Nav = styled.nav`
    display: flex;
    width: 100%;
    padding: 0 50px;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    h2 {
        font-size: 15pt;
    }
    button {
        font-size: 15pt;
        padding: 1px 20px;
        border: 0;
        background-color: green;
        color: #fff;
        line-height: 50px;
        cursor: pointer;
        transition: .2s;
        :hover {
            box-shadow: 0 0 5px #777;
            transition: .2s;
        }
    }
`;

export const List = styled.ul`
    overflow: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 90%;
`;

export const Card = styled.div`
    width: 95%;
    min-height: 180px;
    background-color: rgba(255, 255, 255, 0.76);
    box-shadow: 0 0 3px rgb(65, 65, 65);
    border-radius: 5px;
    color: #000;
    margin: 10px;
    padding: 10px;
    transition: .2s;
    padding-bottom: 0;
    :hover {
        box-shadow: 0 0 6px black;
        transition: .2s;
    }
    h3 {
        font-size: 14pt;
    }
    div {
        font-size: 12pt;
        width: 100%;
        overflow-wrap: break-word;
    }
`;

export const Details = styled.div`
    padding: 20px;
    height: 70%;
    width: 100%;
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        span {
            font-size: 11pt;
            color: #333;
            font-style: italic;
        }
        h2 {
            text-transform: uppercase;
            font-size: 13pt;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .rename {
                display: flex;
                align-items: center;
                color: #777;
                font-style: normal;
                font-size: 9pt;

                label {
                    cursor: pointer !important;

                }
                svg {
                    margin-right: 5px;
                    cursor: pointer !important;

                }
            }
        }

    }
    span {
        font-style: italic;
        color: rgba(0, 0, 0, 0.8);
        font-size: 10pt;
    }
`;

export const FooterOptions = styled.div`
    display: flex;
    height: 30%;
    border-top: 1px solid #888;
    padding: 0;
    align-items: center;
    justify-content: space-between;
`;

export const FooterItem = styled.div`
    height: 90%;
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 20px 10px;
    cursor: pointer;
    :hover {
        background-color: rgba(0, 0, 0, 0.062);
    }
    span {
        margin-left: 10px;
    }
`;
