import styled from "styled-components";

export const Menubar = styled.div`
    position: fixed;
    overflow-y: auto;
    background-color: #fff;
    height: 100%;
    width: 400px;
    bottom: 0;
    top: 0;
    left: ${props => props.active ? '0' : '-400px'};
    transition: 850ms;
    z-index: 10000;
    list-style: none;
    box-shadow: 0 0 10px grey;
    transition: .3s;
    h1 {
        color: black;
        justify-self: center;
    }
    h3 {
        font-size: 11pt;
    }
    h5 {
        color: grey;
        font-size: 9pt;
    }
    span {
        color: black;
        font-size: 20pt;
        cursor: pointer;
    }
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 15vh;
        padding: 0 8%;
        background-color: #1e88e5;
        * {
            color: white;
        }
    }
    li a {
        text-decoration: none;
        padding: 5% 7%;
        color: black;
        display: flex;
        align-items: center;
    }
    li {
        background-color: #fff;
        cursor: pointer
        border-radius: 6px;
        list-style: none;
    }
    li:hover {
        background-color: #eee;
    }
    hr {
        width: 100%;
    }
    img {
        margin-right: 25px;
    }
`;

export const Button = styled.button`
    border: none;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
`;
