import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 15vh;
    background: rgb(25, 156, 25);
    color: #fff;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 7% 2rem 4%;

    .left, .right {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .left svg {
        cursor: pointer;
    }

    img {
        cursor: pointer;
    }

    span.headerItem {
        font-size: 13pt;
        margin-right: 40px;
        cursor: pointer;
        transition: 0.2s;
    }

    span.headerItem:hover {
        font-size: 14pt;
        font-weight: bold;
        text-shadow: 0 0 5px #888;
        transition: 0.2s;
    }

    .logout-menu {
        border: 0 !important;
        box-shadow: 0;
    }
`;
