import React from 'react';
import styled from 'styled-components';

import Header from '../components/Header';

const Container = styled.div`
  height: 85vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin: auto;
    max-width: 500px;
    background: white;
    box-shadow: 2px 2px 10px #d1d1d1;
    padding: 20px;
    border-radius: 20px;
    transform: translateY(-50%);
    h1 {
      font-size: 14pt;
      margin-bottom: 5px;
    }
    p {
      color: #333;
      font-size: 12pt;
      a {
        color: var(--secondary);
        text-decoration: none;
      }
    }
  }

`;

export default function NoCourses() {
  return (
    <>
      <Header />
      <Container>
        <div>
          <h1>Parece que você não possui nenhuma turma ativa como professor 🧐</h1>
          <p>
            Se você está tentando acessar uma página de prova, use o link disponibilizado pelo professor(a) no
            <a href="https://classroom.google.com" target="_blank" rel="noopener noreferrer"> Google Sala de Aula</a>
          </p>
        </div>
      </Container>
    </>
  )
}
