import backendAPI from '../services/backendAPI';

export async function fetchCourses(googleCredentials, logout) {

  try {
    const { data } = await backendAPI(googleCredentials).get('/courses');
    return data;
  } catch (err) {
    logout()
  }

}
