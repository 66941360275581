import React from 'react';
import { Grid, Card, CardContent, CardActions, Typography, Button, Tooltip, tooltipClasses } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import ColorHash from 'color-hash'


//Gera cores aleatórias para o card das turmas
const colorHash = new ColorHash({
  saturation: 0.8,
  lightness: 0.4,
});


//Estilização da ferramenta de dicas
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip classes={{ popper: className }} {...props} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#777',
    color: '#f1f1f1',
    fontSize: theme.typography.pxToRem(13),
  },
}));

export function CourseCard({ course }) {

  const history = useHistory();

  return (
    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
      <Card>
        <CardContent sx={{ cursor: 'pointer', background: colorHash.hex(course.name), textShadow: '1px 1px 5px #000000cc' }} onClick={() => history.push(`/turma/${course.id}`)}>
          <StyledTooltip title={course.name}>
            <Typography noWrap variant="h6" fontWeight="500" color="white">{course.name}</Typography>
          </StyledTooltip>
          <Typography noWrap variant="body1" fontWeight="500" color="white">{course.section || '-'}</Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'space-between' }}>
          <Typography variant="body1" color="inherit">{course.tests} prova(s)</Typography>
          <Typography variant="body1" color="inherit">{course.banks} banco(s)</Typography>
          <Button href={course.alternateLink} target="_blank" rel="noopener">Abrir GSA</Button>
        </CardActions>
      </Card>
    </Grid>
  )

}
