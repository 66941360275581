import styled from 'styled-components';
import stringToColor from 'string-to-color';

export const Container = styled.div`
    width: 100%;
    height: fit-content;
    display: ${({ visible }) => visible ? 'flex' : 'none'};
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 30px;

    ul {
        display: block;
        width: 90%;
        padding: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    ul.hidden {
        height: 85vh;
        overflow: hidden;
    }

    .cardQuestions {
        display: inline-block;
        width: 300px;
        height: 290px;
        border-radius: 5px;
        margin: 10px;
        color: #000;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
        cursor: pointer;
        transition: .3s;
        background-color: #fff;
    }

    .cardQuestions:hover {
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8);
        transition: .3s;
    }

    .cardQuestions main {
        height: 80%;
    }

    .statement {
        background: rgb(1, 15, 1);
        height: 40%;
        border-radius: 5px 5px 0px 0px;
        display: flex;
        align-items: center;
        padding: 10px;
        overflow-wrap: break-word;
    }

    .statement h1 {
        font-size: 10pt;
        font-weight: 400;
        color: #FFF;
        overflow: auto;
        max-height: 100%;
    }

    .cardQuestionsOption {
        padding: 10px;
        height: 60%;
        overflow: auto;
    }

    .cardQuestionsOption div {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 90%;
        white-space: nowrap;
    }

    .cardQuestionsOption span {
        font-size: 12pt;
    }

    .cardQuestionsOption .bold {
        font-weight: bold;
    }

    .buttonCreateQuestion {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer {
        border-top: 1px solid rgba(51, 51, 51, 0.246);
        height: 20%;
        padding: 10px 0;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    footer .delete {
        font-size: 10pt;
        width: 120px;
        padding: 8px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.246);
        transition: .3s;
        margin-left: auto;
    }

    footer .delete:hover {
        transition: .3s;
        box-shadow: 1px 1px 5px rgba(51, 51, 51, 0.603);
    }

`;

export const Button = styled.div`
    cursor: pointer;
    background: green;
    width: fit-content;
    height: fit-content;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    margin: 20px;
    color: #fff;
    font-size: 13pt;
`;

export const ButtonMain = styled.div`
    cursor: pointer;
    background: green;
    width: fit-content;
    height: fit-content;
    text-align: center;
    padding: 15px;
    border-radius: 5px;
    margin: 20px;
    color: #fff;
    font-size: 13pt;
`;

export const Tag = styled.div`
    background: ${props => stringToColor(props.tag)};
    color: #FFF;
    padding: 5px 10px;
    font-size: 10pt;
    border-radius: 10px;
    max-width: 50%;
    height: 20pt;
    display: block;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    word-wrap: break-word;

`;
