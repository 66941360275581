import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Typography, Button, Modal, Box, CardActions } from '@material-ui/core';
import { Delete, Edit, Share } from '@mui/icons-material';
import PopUpEditBank from './PopUpEditBank';
import PopUpShareBank from './PopUpShareBank';

import backendAPI from '../../services/backendAPI';
import { appContext } from '../../context'

export default function QuestionsBankCard({ bank, courseId, getBanks }) {

  const history = useHistory();
  const { googleCredentials } = useContext(appContext);

  const [anchorElement, setAnchorElement] = useState(null);
  const [showModalEditBank, setShowModalEditBank] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showModalDeleteBank, setShowModalDeleteBank] = useState(false);
  const [editBank, setEditBank] = useState({ name: '', description: '' });
  const [isDeleting, setIsDeleting] = useState(false);

  const open = Boolean(anchorElement);

  const handleOpenMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  }

  const handleCloseModalDelete = () => setShowModalDeleteBank(false);

  const openModalEditBank = (bankData) => {
    handleCloseMenu();
    setEditBank(bankData)
    setShowModalEditBank(true);
  }

  const openModalShare = (bankData) => {
    handleCloseMenu();
    setEditBank(bankData)
    setShowShareModal(true);
  }

  const openModalDeleteBank = () => {
    handleCloseMenu();
    setShowModalDeleteBank(true);
  }

  const handleDeleteQuestionsBank = async () => {

    if (isDeleting) return;
    setIsDeleting(true);

    try {
      const newCourses = bank.courses.filter(course => course !== courseId);
      await backendAPI(googleCredentials).patch('/banco/share', {
        bank: bank._id,
        courses: newCourses,
      })
      getBanks();
    } catch (err) {
      console.log(err);
      alert('Erro ao apagar, tente novamente')
    }
    setIsDeleting(false);
    handleCloseModalDelete();
  }

  const deleteModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
  }

  return (
    <>
      <Card>
        <CardContent>
          <Box flex={1} sx={{ cursor: 'pointer' }} onClick={() => history.push(`/bancos/${bank._id}`)}>
            <Typography variant="body1" sx={{ fontSize: 16, fontWeight: '500' }}>{bank.name.toUpperCase()}</Typography>
            <Typography variant="subtitle1">{bank.description || '-'}</Typography>
          </Box>
        </CardContent>
        <CardActions>
          <Button size="small" color="inherit" onClick={() => openModalEditBank(bank)}>
            <Edit fontSize="small" color="primary" sx={{ mr: 0.7 }} />
            Renomear
          </Button>
          <Button size="small" color="inherit" onClick={() => openModalShare(bank)}>
            <Share fontSize="small" color="success" sx={{ mr: 0.7 }} />
            Compartilhar
          </Button>
          <Button size="small" color="inherit" onClick={() => openModalDeleteBank()}>
            <Delete fontSize="small" color="error" sx={{ mr: 0.7 }} />
            Apagar
          </Button>
        </CardActions>
      </Card>
      <Modal
        open={showModalDeleteBank}
        onClose={handleCloseModalDelete}
      >
        <Box sx={deleteModalStyle}>
          <Typography variant="h6">Tem certeza que deseja excluir o banco?</Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>Ele será removido apenas dessa turma</Typography>
          <Button variant="contained" color="error" sx={{ mr: 2 }} onClick={handleDeleteQuestionsBank}>
            {isDeleting ? 'Excluindo...' : 'Excluir'}
          </Button>
          <Button variant="outlined" color="info" onClick={handleCloseModalDelete} >Cancelar</Button>
        </Box>
      </Modal>
      <PopUpEditBank showModal={showModalEditBank} setShowModal={setShowModalEditBank} bankData={editBank} getBanks={getBanks} />
      <PopUpShareBank showModal={showShareModal} getBanks={getBanks} setShowModal={setShowShareModal} bank={editBank} />
    </>
  )
}
