import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';

import cloneDeep from 'lodash.clonedeep';
import ReactTooltip from 'react-tooltip';
import 'react-quill/dist/quill.snow.css';
import MobileDetect from 'mobile-detect';

import { Typography, TextField, Tooltip, tooltipClasses } from '@material-ui/core';

import backendAPI from '../../services/backendAPI';

import { appContext } from '../../context';

import Loading from '../Loading';
import Header from '../components/Header';
import Timer from './Timer';
import ConfirmInitTest from './ConfirmInitTest';

import { MdCheck, MdClose } from 'react-icons/md';

import extractContent from '../../utils/extractHtmlContent';

import {
  Container,
  TestArea,
  TestDetails,
  Button,
  Images,
  Image,
  Feedback,
  Grade,
  Statement,
  Options,
  Option,
  Question,
  Questions,
  QuestionDetail,
  Submit,
} from './styles';

import MobileIntefaceTest from './MobileInterfaceTest';

Array.prototype.hasSameElements = function (arrayToCompare) {
  if (!arrayToCompare) return false
  if (this.length !== arrayToCompare.length) return false;

  return this.every(element => arrayToCompare.includes(element));
}

function TestInterfaceForStudents({ location }) {

  const { googleCredentials, setIsTestInterface, signOut } = useContext(appContext);

  setIsTestInterface(true);

  const [test, setTest] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [hasWrongAnswer, setHasWrongAnswer] = useState([]);
  const [testNotStarted, setTestNotStarted] = useState(true);
  const [loadingTest, setLoadingTest] = useState(false);

  const getTest = async () => {

    setLoadingTest(true);

    const testId = location.pathname.split('/')[2];

    try {
      let { data } = await backendAPI(googleCredentials).get(`/provas/sessao?test=${testId}`);

      data.questions.forEach(question => {
        if (question.answer.length > 0) question.answer = { current: [...question.answer], saved: [...question.answer] }
      });

      setTest(data);
      setTestNotStarted(false);

    } catch (err) {
      let { data } = await backendAPI(googleCredentials).get(`/provas/${testId}`);
      setTest(data || 'Not found');
    }

    setLoadingTest(false);

  }

  const startTest = async () => {

    setLoadingTest(true);

    const testId = location.pathname.split('/')[2];

    try {

      let { data } = await backendAPI(googleCredentials).post(`/provas/sessao?test=${testId}`);
      data.questions.forEach(question => {
        if (question.answer.length > 0) question.answer = { current: [...question.answer], saved: [...question.answer] }
      });

      setTest(data);
      setTestNotStarted(false);

    } catch (err) {
      alert('Erro ao iniciar prova.');
    }

    setLoadingTest(false);

  }

  useEffect(() => { getTest(); }, []);

  useEffect(() => {

    if (!test || isNaN(test.publishedGrade)) return;

    test.questions.forEach(question => {


      for (let option of question.question.options) {
        if (
          option.isCorrect !== question.answer?.includes?.(option._id) ||
          (question.answer.length > 0 &&
            option.isCorrect !== option.isCorrect !== question.answer.current?.includes?.(option._id))
        ) {
          setHasWrongAnswer(prev => [...prev, question.question._id]);
          break;
        }
      }
    });

  }, [test]);

  const indexOfQuestion = (question) => {
    for (let i = 0; i < test?.questions.length; i += 1) {
      if (test.questions[i].question._id === question?._id) return i
    }
    return -1;
  }

  const handleAnswerChange = (answer, question) => {

    if (test.finished) return;

    const index = indexOfQuestion(question.question);
    const testCopy = cloneDeep(test);

    if (question.question.type === 'singleChoice' && question.answer.saved) {
      testCopy.questions[index].answer.current = [answer];
      return setTest(testCopy);
    }

    if (question.question.type === 'open' && question.answer.saved) {
      testCopy.questions[index].answer.current = [answer];
      return setTest(testCopy);
    }

    // Remove do array caso esteja lá
    if (question.answer.current?.includes?.(answer)) {
      testCopy.questions[index].answer.current.splice(testCopy.questions[index].answer.current.indexOf(answer), 1);
      return setTest(testCopy);
    }

    // Adiciona caso o elemento não exista no array
    if (question.answer.current) {
      testCopy.questions[index].answer.current = [...testCopy.questions[index].answer.current, answer]
      return setTest(testCopy);
    }

    // Cria o array current, caso ele não exista e adiciona a resposta
    testCopy.questions[index].answer = { current: [answer], saved: [] }
    return setTest(testCopy);

  }

  /* const handleSave = async (question) => {

      // Verifica se alguma resposta foi alterada para um valor diferente do salvo
      const isSaved = question.answer.current?.hasSameElements(question.answer.saved);

      if (isSaved || !question.answer.current) return;

      const questionCopy = { ...question };

      questionCopy.answer = question.answer.current;

      backendAPI(googleCredentials).patch(`/provas/sessao/${test._id}`, { question: questionCopy })
          .then(({ data }) => {
              const testCopy = cloneDeep(test);
              data.questions.forEach(({ answer }, index) => {
                  if (answer === []) return;
                  testCopy.questions[index].answer = { current: testCopy.questions[index].answer.current || [], saved: [...answer] };
              });
              setTest(testCopy);
          })
          .catch(err => {
              alert('Ocorreu um erro ao salvar a questão');
          })
  } */

  const handleSubmit = async () => {

    if (submitting) return;

    const hasUnansweredQuestions = test.questions.some(question => {
      if (question?.answer?.current?.length === 0 || question.answer.length === 0) return true;

      return false;
    });

    let consent = false;

    if (hasUnansweredQuestions) {
      consent = window.confirm('Existe uma ou mais questões sem respostas, deseja enviar mesmo assim? Não será possível alterar as respostas após entregar.');

      if (!consent) return;
    }

    if (!consent) consent = window.confirm('Deseja realmente enviar? Não será possível alterar as respostas após entregar.');

    if (!consent) return;

    setSubmitting(true);

    const copy = cloneDeep(test);

    copy.questions.forEach(question => {
      if (question.answer.current) question.answer = question.answer.current;
    });

    try {

      //Desestruturação da variável data de dentro da API do backend
      const { data } = await backendAPI(googleCredentials).post(`/provas/sessao/${test._id}/submit`, copy);

      alert('Prova enviada!');
      window.location.href = data.redirectTo;

    } catch (err) {
      console.log(err.response.data)
      if (err.response.data.savedOnDatabase) {
        setTest({ ...test, finished: true });
        alert('A prova foi enviada para o professor, mas ocorreu um erro ao marcar a atividade como concluída no Google Classroom. Você pode fazer isso manualmente sem prejuizos em sua nota.');
      } else {
        alert('Ocorreu um erro ao enviar a prova');
      }
    }

    setSubmitting(false);

  }
  const getAnswerText = (question) => {

    const index = indexOfQuestion(question);

    if (question?.type === 'open') {
      return test.questions[index].answer.current || 'Responder';
    }

    const options = test.questions[index].question.options?.map(option => {
      if (test.questions[index].answer.current && test.questions[index].answer.current.includes(option._id)) return extractContent(option.text);
    })?.filter?.(option => option)

    return options?.length > 0 ? options.join(' | ') : 'Responder';
  }

  const getImage = async (imageName, testId) => {
    const { data } = await backendAPI(googleCredentials).get(`/images/${imageName}?session=${testId}`, {
      responseType: 'blob'
    });
    const url = URL.createObjectURL(data);
    return url;
  }

  const refreshPageOnFirstLoad = () => {

    if (sessionStorage.getItem(test._id)) return;

    sessionStorage.setItem(test._id, 'true');
    window.location.reload(true);
  }

  function getOptionStatus(question, option) {
    if (!isNaN(test.publishedGrade)) {
      if (question.answer.current?.includes?.(option._id)) {
        return option.isCorrect ? 'correct' : 'wrong'
      } else {
        return option.isCorrect ? 'shouldCheck' : null
      }
    } else {
      return null
    }

  }

  const isMobile = () => {
    return true;
    const mobiledetect = new MobileDetect(window.navigator.userAgent || window.navigator.vendor || window.opera);
    return mobiledetect.isPhoneSized(650);
  }

  //Verifica se o usuário está em um dispositivo móvel (Se sim, redireciona pro componente de responsividade)
  if (isMobile()) return <MobileIntefaceTest location={location} />

  if (!test) return <Loading />
  if (loadingTest) return <Loading loadingTest={loadingTest} />
  if (testNotStarted) return <ConfirmInitTest test={test} startTest={startTest} />
  return (
    <>
      <Header />
      <Container div="container" onLoad={() => refreshPageOnFirstLoad(signOut)}>

        <TestArea ref={el => {
          el &&
            el.addEventListener("selectstart", event => event.preventDefault());
        }}>
          <main>
            <header>
              <Typography>{test.test.name}</Typography>
              <div>
                <span>Valor da prova: {test.test.value}</span>
                {
                  !isNaN(test.publishedGrade)
                    ? <Grade className="grade" correctness={test.publishedGrade / test.test.value * 100}>
                      Aproveitamento: {(test.publishedGrade / test.test.value * 100).toFixed(2)}%
                    </Grade>
                    : null
                }
                {
                  !isNaN(test.publishedGrade)
                    ? <Grade className="grade" correctness={test.publishedGrade / test.test.value * 100}>NOTA: {test.publishedGrade.toFixed(2)}</Grade>
                    : null
                }
              </div>
            </header>

            {
              /*Percorre questions e insere estilização de editor de texto com Statement*/
              test.questions.map(question => (
                <Question key={question.question._id} id={question.question._id}>
                  {
                    test.publishedGrade && <Typography color="primary">Nota: {question.grade.toFixed(2)}</Typography>
                  }
                  <div className="ql-container ql-snow">
                    <Statement className="ql-editor" dangerouslySetInnerHTML={{ __html: question.question.statement }} />
                  </div>

                  <Options>

                    {/*Mapeia question para verificar se ela é uma questão aberta
                                        se for, insere uma área de texto para o aluno responder à questão*/}
                    {
                      question.question.type === 'open'
                        ? test.finished
                          ? <Typography sx={{ color: '#333' }}variant="body1">{ question.answer.current[0] }</Typography>
                          : <TextField variant="filled" multiline label="Resposta"
                            onChange={(event) => handleAnswerChange(event.target.value, question)}
                          />
                        : null
                    }

                    {
                      question.question.options.map(option => (question.question.type === 'singleChoice'
                        ? <Option
                          key={option._id}
                          onChange={event => handleAnswerChange(option._id, question)}
                          getStatus={() => getOptionStatus(question, option)}
                        >

                          {
                            <input
                              id={option._id}
                              type="radio"
                              name={question.question._id}
                              checked={question.answer.current?.[0] === option._id}
                              readOnly={test.finished}
                            />
                          }
                          <div className="ql-container ql-snow">
                            <Statement className="ql-editor optionText" dangerouslySetInnerHTML={{ __html: option.text }} />
                          </div>
                          {
                            !isNaN(test.publishedGrade) && question.answer.current?.[0] === option._id && option.isCorrect !== undefined
                              ? option.isCorrect ? <MdCheck color="green" /> : <MdClose color="red" />
                              : null
                          }

                        </Option>
                        : <Option
                          key={option._id}
                          getStatus={() => getOptionStatus(question, option)}
                        >
                          {
                            test.finished
                              ? <input
                                id={option._id}
                                onChange={() => handleAnswerChange(option._id, question)}
                                type="checkbox"
                                checked={question.answer.current?.includes?.(option._id)}
                                readOnly
                              />
                              : <input
                                id={option._id}
                                onChange={() => handleAnswerChange(option._id, question)}
                                type="checkbox"
                                defaultChecked={question.answer.current?.includes?.(option._id)}
                              />
                          }
                          <div className="ql-container ql-snow">
                            <Statement className="ql-editor optionText" dangerouslySetInnerHTML={{ __html: option.text }} />
                          </div>
                          {
                            !isNaN(test.publishedGrade) && question.answer.current?.includes?.(option._id) && option.isCorrect !== undefined
                              ? option.isCorrect ? <MdCheck color="green" /> : <MdClose color="red" />
                              : null
                          }
                        </Option>
                      ))
                    }
                  </Options>

                  {
                    test.finished && !isNaN(test.publishedGrade) && hasWrongAnswer.includes(question.question._id)
                      ? <>
                        <Statement>Resposta Correta:</Statement>
                        <Options>
                          {
                            question.question.options.map(option => (
                              option.isCorrect ?
                                question.question.type === 'singleChoice'
                                  ? <Option
                                    key={option._id}
                                    onChange={event => handleAnswerChange(option._id, question)}
                                  >
                                    <input
                                      id={option._id}
                                      type="radio"
                                      name={question.question._id + '-correct'}
                                      checked
                                      readOnly
                                    />

                                    <div className="ql-container ql-snow">
                                      <Statement className="ql-editor optionText" dangerouslySetInnerHTML={{ __html: option.text }} />
                                    </div>

                                  </Option>
                                  : <Option key={option._id}>
                                    <input
                                      id={option._id}
                                      onChange={() => handleAnswerChange(option._id, question)}
                                      type="checkbox"
                                      checked
                                      readOnly
                                    />
                                    <div className="ql-container ql-snow">
                                      <Statement className="ql-editor optionText" dangerouslySetInnerHTML={{ __html: option.text }} />
                                    </div>

                                  </Option>

                                : null
                            ))
                          }
                        </Options>
                      </>
                      : null
                  }

                  {
                    question.comment
                      ? <><hr /><Feedback><strong>Comentário particular:</strong> {question.comment}</Feedback></>
                      : null
                  }

                  {
                    test.finished && question.question.feedback
                      ? <><hr /><Feedback><strong>Considerações gerais sobre a questão (para toda a turma):</strong> {question.question.feedback}</Feedback></>
                      : null
                  }

                </Question>
              ))

            }

          </main>
        </TestArea>

        <TestDetails>

          {
            !test.finished
              ? <Timer test={test} />
              : null
          }

          <Questions>
            <Typography><strong>Questões</strong></Typography>

            {
              test.questions.map(({ question, ...rest }, index) => (
                <QuestionDetail key={question._id} onClick={() => window.location.href = `#${question._id}`}>
                  <Typography variant="body1">{index + 1}. {getAnswerText(question)}</Typography>
                </QuestionDetail>
              ))
            }

          </Questions>

          {
            !test.finished
              ? <Submit data-tip data-for="submit-tooltip" onClick={handleSubmit} download>{submitting ? 'Entregando, aguarde...' : 'Entregar'}</Submit>
              : null
          }

        </TestDetails>
        <ReactTooltip className="tip" id="save-tooltip" effect="solid" type="info" place="right">
          <p>Ao salvar a questão você previne que problemas como quedas de energia atrapalhem seu progresso ;D (as questões são salvas individualmente)</p>
        </ReactTooltip>

        <ReactTooltip className="tip" id="submit-tooltip" effect="solid" type="dark" place="bottom">
          <p>Após entregar a prova não será mais possível alterar as respostas.</p>
        </ReactTooltip>
      </Container>
    </>
  )

}

export default TestInterfaceForStudents;
