import styled from 'styled-components';

export const Container = styled.div`
    * {
        /* border: 1px solid red; */
    }
    display: flex;
    flex-direction: column;
    gap: 3%;
    width: 100%;
    height: 85vh;
    background: #FFF;
    padding: 2% 0 2% 10%;
    min-height: 85vh;
    overflow: auto;

    .tip-icon {
        background-color: white;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transform: translate(-7px, -7px);
        color: #3C7EE1;
        box-shadow: 0 0 5px #888;
    }
    .save {
        display: flex;
    }
`;

export const TestArea = styled.div`
    background-color: #f1f1f1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    width: 70%;
    display: flex;
    justify-content: center;
    padding: 3%;
    height: fit-content;
    main {
        width: 90%;
        padding: 0 30px;
        header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
                font-size: 0.8rem;
            }
        }
        h1 {
            text-transform: uppercase;
            font-size: 1.2rem;
        }
        
    }
`;

export const Grade = styled.span`
    margin-left: 50px;
    font-weight: bold;
    color: ${({ correctness }) => {
        switch (true) {
            case (correctness > 75):
                return '#61a750';
            case (correctness > 50):
                return '#1672a9';
            case (correctness > 30):
                return '#d09f12';
            default:
                return '#ff5a33';
        }
    }};
`;

export const Question = styled.div`
    border-bottom: 1px solid #ccc;
    padding: 40px 0;
    .ql-container {
        border: 0 !important;
        font-size: 1px !important;
    }
`;

export const Statement = styled.div`
    width: 100%;
    line-height: 23pt;
    padding: 10px 0 !important;
    font-size: 15px;
    img {
        max-width: 100%;
    }
`;

export const Images = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
`;

export const Image = styled.img`
    max-width: 100%;
`;

export const Feedback = styled.p`
    font-size: 0.6rem;
    font-weight: 400;
    padding: 30px 0;

    strong {
        font-weight: bold;
    }
`;

export const Options = styled.div`
    font-weight: 400;
    width: 100%;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    input {
        width: 16px;
        height: 16px;
        margin-right: 20px;
        cursor: pointer;
    }
    label {
        overflow-wrap: break-word;
        width: 90%;
        cursor: pointer;
        transition: 0.15s;
    }
    label:hover {
        text-shadow: 0 0 10px #777;
        transition: 0.15s;
    }
    .openAnswer {
        width: 120px;
        height: 25px;
        padding: 10px;
    }
`;

export const Option = styled.div`
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px;

        border: ${({ getStatus }) => getStatus && getStatus() === 'shouldCheck' ? '1px solid green' : '0'};

        background-color: ${({ getStatus }) => {

            if (!getStatus) return;

            const status = getStatus();

            if (!status) return 'transparent';

            return status === 'correct' || status === 'shouldCheck' ? '#d2f6dc' : '#f1d2cf';

        }};
        :hover {
            background: #ccc !important;
            transition: .3s;
            * {
                cursor: pointer;
            }
        }
        svg {
            font-size: 16pt;
        }
`;

export const Button = styled.button`
    background: rgb(25, 156, 25);
    font-size: 1rem;
    font-weight: bold;
    color: #FFF;
    border: 0;
    padding: 8px 50px;
    text-transform: uppercase;
    cursor: pointer;
    transition: .2s;
    :hover {
        box-shadow: 0 0 10px #888;
        transition: .2s;
    }
`;

export const TestDetails = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    font-size: 1.6rem;
    padding:2%;
    max-height: 80vh;
    position: fixed;
    right: 2%;
    top: 15vh;
    h2 {
        font-size: 1.2rem;
        padding: 10px 0;
    }
`;

export const Questions = styled.div`
    width: 100%;
    max-height: 100%;
    overflow: auto;
    margin-bottom: 20px;
`;

export const QuestionDetail = styled.div`
    padding: 15px 0;
    cursor: pointer;
    transition: 0.15s;
    :hover {
        text-shadow: 0 0 10px #999;
        transition: 0.15s;
    }
    p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    span {
        font-size: 1.5rem;
        font-style: italic;
    }
    span.saved {
        color: rgb(25, 156, 25);
    }
    span.pending {
        color: red;
    }
`;

export const Submit = styled(Button)`
    background: #3C7EE1;

`;
