import React, { useRef, useEffect, useCallback, useState, useContext } from 'react';
import { useSpring, animated } from 'react-spring';

import backendAPI from '../../../services/backendAPI';

import { appContext } from '../../../context';

import styled from 'styled-components';

import { MdClose } from 'react-icons/md';

import { Typography, Button, InputLabel } from '@material-ui/core';

const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalWrapper = styled.div`
    width: 800px;
    height: 500px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: grid;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 10;
    border-radius: 10px;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    line-height: 1.8rem;
    color: #141414;
    h1 {
        display: flex;
        text-align: top;
        position: relative;
        font-size: 25pt;
    }
    h3 {
        justify-content: center;
        align-items: center;
        font-size: 25pt;
        margin: 20px;
    }
    p {
        text-align: center;
        font-size: 10pt;
        padding: 20px;
        margin-bottom: 10px;
    }
    input {
        height: 3em;
        width: 50em;
        margin-bottom: 40px;
        border-radius: 4px;
        background-color: #fff;
        padding: 4px;
        transition: .5s;
        border: 1px solid rgb(150, 150, 150);
    }

    input:focus {
        border: 2px solid black;
    }
    
    button {
        height: 50px;
        width: 280px;
        border-radius: 10px;
        background-color: green;
        color: #fff;
        border: none;
        font-size: 30px;
        box-shadow: 1px 1px 1px solid black;
        cursor:pointer;
        transition: 0.5s;
    }
    button:hover {
        height: 50px;
        width: 280px;
        border-radius: 20px;
        background-color: black;
        color: #fff;
        border: none;
        font-size: 30px;
        box-shadow: 1px 1px 1px solid black;
        cursor:pointer;
        transition: 0.5s;
    }
`;

const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;

export default function PopUpBank ({ showModal, setShowModal, bankData, getBanks }) {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [banks, setBanks] = useState([]);

    const { googleCredentials } = useContext(appContext);

    useEffect(() => {
        setName(bankData.name);
        setDescription(bankData.description);
    }, [bankData]);

    const modalRef = useRef();

    const handleSave = async () => {
        const response = await backendAPI(googleCredentials).put(`/banco?id=${bankData._id}`, {
            name,
            description,
        });
        getBanks();
        setShowModal(false)
    }

    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? 'translateY(0%)' : 'translateY(-100%)'
    });

    const closeModal = e => {
        if(modalRef.current === e.target) {
            setShowModal(false)
        }
    }

    const keyPress = useCallback(e => {
        if(e.key === 'Escape' && showModal) {
            setShowModal(false)
        } else if (e.key === 'Enter' && showModal) {
            handleSave();
        }
    }, [setShowModal, showModal])

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress)
    }, [keyPress])

    return (
        <>
        {showModal ? (
            <Background ref={modalRef} onClick={closeModal}>
                <animated.div style={animation}>
                <ModalWrapper showModal={showModal}>
                <ModalContent>
                    <Typography variant="h3"> Nome do banco</Typography>
                    <input maxLength="30" onChange={(event) => setName(event.target.value)} value={name}></input>

                    <Typography variant="h3">Descrição</Typography>
                    <input maxLength="50" onChange={(event) => setDescription(event.target.value)} value={description}></input>

                    <Button variant="button" onClick={handleSave}>Salvar</Button>
                </ModalContent>
                <CloseModalButton aria-label='Close modal' onClick={() => setShowModal(prev => !prev)} />
                </ModalWrapper>
            </animated.div>
            </Background>
        ) : null}
    </>
    )
};
