import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 5% 10%;
    .exportTable {
        background-color: #5891ce;
    }
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        div {
            display: flex;
            align-items: center;
        }
    }
    * {
       /* border: 1px solid #ccc; */
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
`;

export const List = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
`;

export const ListItem = styled.li`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-bottom: 20px;
    padding: 10px;
    box-shadow: 1px 1px 4px #00000030;
    background: #fff;
    border-radius: 7px;
    :hover {
        box-shadow: 0 0 10px #999;
        transition-duration: .5s;
    }
`;

export const Overview = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        flex-grow: 1;
        text-align: left;
        font-size: 12pt;
        font-weight: 400;
        margin-left: 10px;
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;
        span {
            font-style: italic;
            color: #333;
            font-size: 10pt;

            svg {
                height: 13pt;
                width: 13pt;
                display: flex;
                align-items: center;
            }

        }
    }

`;

export const Options = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11pt;
    font-weight: bold;
    p {
        font-weight: 400;
        font-size: 9.3pt;
        font-style: italic;
        margin-left: 10px;
        padding: 0;
    }
`;

export const Option = styled.div`
    color: ${props => props.color ? props.color : '#222'};
    display: flex;
    align-items: center;
    justify-content: center;
    transform: ${props => props.showDetails ? 'rotate(-180deg)' : ''};
    transition: .2s;
    padding: 2% 0;
    flex-grow: 1;
    :hover {
        label {
            transition: .2s;
            text-shadow: 0 0 10px rgba(0, 0, 0, .6);
        }
        svg {
            transition: .2s;
            background: #e5e5e5;
            border-radius: 50%;
        }
    }
    label {
        margin-left: 10px;
    }
    input {
        width: 50px;
        border: 0;
        border-bottom: 1px solid #333;
    }
    p {
        width: fit-content;
        margin: 0 10px;
    }
    * {
        cursor: pointer;
    }
`;

export const Details = styled.div`
    width: 100%;
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: auto;
    padding: 15px;
    h3, p, span {
        text-align: left;
    }
    p {
        padding-bottom: 0;
    }
    span {
        display: block;
    }
    h3 {
        font-weight: bold;
        height: 100%;
        font-size: 12pt;
        padding-top: 15px;
    }
`;

export const Question = styled.div`
    width: 100%;
    margin-bottom: 15px;
    p {
        padding-top: 12px;
    }
    & + div {
        border-top: 1px solid #ccc;
    }
    label {
        width: 90%;
        overflow-x: auto;
    }
    .ql-editor {
        overflow: hidden;
    }
    .ql-container {
        width: 100%;
        max-height: 1000px;
        overflow: auto;
        margin: 30px 0;
    }
`;

export const Answers = styled.div`
    font-size: 10pt;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 10px 0;
`;

export const Comment = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: 20px;
    p {
        padding: 10px 0;
        margin: 0;
        font-weight: bold;
        font-size: 12pt;
        cursor: pointer;
        color: #5891ce;
        transition: .2s;
        :hover {
            transition: .2s;
            text-shadow: 1px 1px 3px #ccc;
        }
    }
    textarea {
        width: 45%;
        min-height: 100px;
        height: fit-content;
        resize: vertical;
        margin-bottom: 10px;
        padding: 10px;
        font-size: 12pt;
        letter-spacing: .5px;
    }
    button {
        width: fit-content;
        background-color: rgb(25, 156, 25);
        border: 0;
        padding: 5px 20px;
        font-size: 12pt;
        color: #fff;
        cursor: pointer;
        transition: .2s;

        :hover {
            transition: .2s;
            box-shadow: 1px 1px 8px #999;
        }
    }
`;

export const Button = styled.button`
    background: ${props => props.active ? 'rgb(25, 156, 25)' : 'rgba(25, 156, 25, .6)'};
    font-size: 16px;
    font-weight: bold;
    color: #FFF;
    border: 0;
    padding: 10px 60px;
    text-transform: uppercase;
    cursor: ${props => props.active ? 'pointer' : 'initial'};
    transition: .2s;
    margin-left: 20px;
    div {
        display: flex;
        align-items: center;
    }
    a {
        text-decoration: none;
        color: #FFF;
        margin-left: 30px;
    }
    :hover {
        box-shadow: 0 0 10px #777;
        transition: .2s;
    }
`;
