import React, { useEffect, useState, useContext } from 'react';
import { Grid, Container, Typography, Skeleton } from '@material-ui/core';
import Loading from '../Loading';
import Header from '../components/Header';
import NoCourses from './NoCourses';

import { appContext } from '../../context';
import { fetchCourses } from '../../utils/fetchCourses';

import { CourseCard } from './CourseCard';


function Main() {

  const [isTeacher, setIsTeacher] = useState(false);

  //Desestrutura estas variáveis de dentro do appContext
  const { googleCredentials, courses, setCourses, signOut } = useContext(appContext);
  useEffect(() => {
    async function getCourses() {
      const response = await fetchCourses(googleCredentials, signOut)
      setCourses(response)
    }

    if (courses[0] === 'searching' || !courses) getCourses();

  }, []);

  useEffect(() => {
    if (courses.length > 0) setIsTeacher(true)
    else setIsTeacher(false)
  }, [courses]);


  // if (courses[0] === 'searching') return <Loading />
  //Se não houver turmas em que o usuário seja professor, returna o componente NoCourses
  if (courses.length === 0) return <NoCourses />

  return (
    <>
      <Header />
      <Container sx={{ pt: 6, pb: 6 }}>
        <Typography variant="h5" align="center" mb={4}>Turmas</Typography>
        <Grid
          container
          direction="row"
          spacing={5}
          columns={{ xs: 1, sm: 2, md: 2, lg: 3 }}
        >
          {
            courses?.[0] === 'searching'
              ? Array(18).fill(
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                  <Skeleton variant="rect" height={100} />
                  <Skeleton variant="text" height={40} />
                </Grid>
              )
              : courses.map(course => <CourseCard course={course} />)
          }
        </Grid>
      </Container>
    </>
  );
}

export default Main;
