import React, { useState, useEffect, useContext } from 'react';

import { MdDelete } from 'react-icons/md';

import Header from '../components/Header';
import PopUpConfirmDelete from './PopUpConfirmDelete';
import { PopUpQuestions } from '../components/PopUpQuestions';
import Loading from '../Loading'
import { appContext } from '../../context';

import { Container, Tag, Button, ButtonMain } from './styles';
import backendAPI from '../../services/backendAPI';

import extractContent from '../../utils/extractHtmlContent';
import { Typography, Button as MUIButton } from '@mui/material';

function ListBankQuestions({ location, bank }) {

  const bankId = location.pathname.split('/')[2];

  const { googleCredentials } = useContext(appContext);
  const [openedQuestion, setOpenedQuestion] = useState(null);
  const [showModalQuestion, setShowModalQuestion] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showModalQuestions, setShowModalQuestions] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [questionToDetail, setQuestionToDetail] = useState({ statement: "", options: [], images: [] });
  const [bankData, setBankData] = useState(bank)
  const [questions, setQuestions] = useState('searching');

  const openModalQuestion = (question) => {
    setShowModalQuestion(prev => !prev);
    setQuestionToDetail(question)
  }

  const openDeleteConfirmation = (id) => {
    setDeleteId(id);
    setShowConfirmation(prev => !prev);
  }

  const openModalCreateQuestions = () => {
    setShowModalQuestions(prev => !prev);
  }

  const getQuestions = async () => {
    try {
      const response = await backendAPI(googleCredentials).get(`/banco/${bankId}`);
      setQuestions(response.data.questions);
      setBankData(response.data);
    }
    catch (err) { }
  }

  useEffect(() => {
    getQuestions();
  }, []);

  if (questions === 'searching') return <Loading />

  return (
    <>
      <PopUpConfirmDelete showConfirmation={showConfirmation} setShowConfirmation={setShowConfirmation} questionId={deleteId} getQuestions={getQuestions} />
      <Header>
        <MUIButton onClick={openModalCreateQuestions} sx={{ color: 'white' }}>Criar Questão</MUIButton>
      </Header>
      <PopUpQuestions visible={showModalQuestions} closeModal={() => setShowModalQuestions(false)} bank={bankData} getQuestions={getQuestions} />

      <Container visible={!showModalQuestion}>
        {
          questions.length === 0
            ? <Button className="buttonCreateQuestion" onClick={openModalCreateQuestions}>Criar Questões</Button>
            : <>
              <ul className={showModalQuestions || showConfirmation ? "hidden" : ""}>
                {
                  questions.map(question => (
                    <li key={question._id} className="cardQuestions">
                      <PopUpQuestions visible={openedQuestion === question._id} closeModal={() => setOpenedQuestion(null)} questionData={question} bank={bankData} getQuestions={getQuestions} newQuestion={false} />

                      <main onClick={() => setOpenedQuestion(question._id)}>
                        <div className="statement">
                          <h1>{extractContent(question.statement)}</h1>
                        </div>
                        <div className="cardQuestionsOption">
                          {
                            question.options.map((option, index) => (
                              <div key={option._id} className={option.isCorrect ? "bold" : ""}>
                                <span>{index + 1}. </span>
                                <span>{extractContent(option.text)}</span>
                              </div>
                            ))
                          }
                        </div>
                      </main>
                      <footer>
                        {question.tag && <Tag tag={question.tag} className="tag" title={question.tag}>{question.tag}</Tag>}
                        <div className="delete" onClick={() => openDeleteConfirmation(question._id)}>
                          <MdDelete size="20" color="red" />
                          <span>Apagar</span>
                        </div>
                      </footer>
                    </li>
                  ))
                }
              </ul>
            </>
        }
      </Container>
    </>
  )
}

export default ListBankQuestions;
